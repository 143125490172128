import { ApplicationResourceAttributes } from 'applications-types-lib'

export type Program = ApplicationResourceAttributes['programsSelected'][number]

export function useGetPrograms() {
  return {
    programs: [
      {
        priority: 1,
        programInformation: {
          programId: 'Vt8Tl-lUNGbPZqyR5hy02',
          programTitle: 'Art & Design Foundation',
        },
      },
      {
        priority: 1,
        programInformation: {
          programId: 'AW9h78o1YlYo-GDmWAIYR',
          programTitle: 'Computer Programming and Analysis',
        },
      },
      {
        priority: 1,
        programInformation: {
          programId: 'PYKQuRmyvSg5ofYSMmxjY',
          programTitle: 'Cyber Security',
        },
      },
      {
        priority: 1,
        programInformation: {
          programId: 'mVkYQa7w_AgqnklTSiKLR',
          programTitle: 'Digital Experience Design',
        },
      },
    ],
  }
}
