import { Breadcrumb, Flex } from '@applyboard/crystal-ui'
import { ClipboardOutlineIcon } from '@applyboard/ui-icons'

export function BackToApplication() {
  return (
    <Flex pb={{ xs: 10, sm: 6 }}>
      <Breadcrumb
        items={[
          {
            leadIcon: ClipboardOutlineIcon,
            href: '/',
            label: 'My Applications',
          },
          {
            label: 'New Application',
            href: '/applications/new',
          },
        ]}
      />
    </Flex>
  )
}
