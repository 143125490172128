import styled from '@emotion/styled'
import { RawApplicationResponse } from '../../../hooks'
import { ApplicationFileLink } from './ApplicationFileLink'
import { FileData } from 'applications-types-lib'
import { useApplicationFormContext } from './ApplicationForm'

type ApplicationFileListProps = {
  application: RawApplicationResponse['data']
  disabled?: boolean
  documents?: Array<{
    id: string
    file: File
  }>
  fileType: string
  formFiles?: FileData
}

export function ApplicationFileList(props: ApplicationFileListProps) {
  const { files, deleteFile } = useApplicationFormContext()

  function hasFileError(id: string) {
    return !props.application?.meta?.files?.[id]?.download?.url
  }

  return (
    <FileList>
      {Object.entries(files).map(([id, file]) => {
        if (
          file?.type !== props.fileType ||
          props.documents?.some(document => document.id === id)
        ) {
          return null
        }

        return (
          <ApplicationFileLink
            key={id}
            fileId={id}
            filename={file?.fileName || ''}
            helpText={hasFileError(id) ? 'There was a problem uploading this file' : undefined}
            intent={hasFileError(id) ? 'negative' : 'secondary'}
            link={props.application?.meta?.files?.[id]?.download?.url}
            onDelete={!props.disabled ? deleteFile : undefined}
          />
        )
      })}
    </FileList>
  )
}

const FileList = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  margin: '16px 0px 0px',
  padding: '0px',
  width: '100%',
})
