import { CheckCircleFillIcon, CircleFillIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { RawApplicationResponse } from '../../../hooks'
import { isTabCompleted } from './utils'

type TimelineItemDotProps = {
  selectedTab: number
  index: number
  application: RawApplicationResponse['data'] | null
}

export function TimelineItemDot(props: TimelineItemDotProps) {
  if (props.selectedTab === props.index + 1) {
    return <CircleFillIcon intent="primary" />
  }

  if (isTabCompleted({ index: props.index + 1, application: props.application })) {
    return <CheckCircleFillIcon intent="positive" />
  }

  return <InactiveCircle />
}

const InactiveCircle = styled.div(({ theme }) => ({
  width: 20,
  height: 20,
  border: `2px solid ${theme.colors.borderInactive}`,
  borderRadius: '50%',
}))
