import {
  Combobox,
  createForm,
  DatePicker,
  FileInfo,
  FileUpload,
  Flex,
  Heading,
  Select,
  Text,
  TextInput,
} from '@applyboard/crystal-ui'
import {
  COUNTRY_NAMES,
  CountryIsoCode,
} from 'schools-domain-backend-utils/dist/common-types/country'
import {
  getFilesOfType,
  RawApplicationResponse,
  useGetFileObjectFromApplicationFiles,
  useUpdateApplication,
  useUploadFile,
} from '../../../hooks'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { ApplicationFormCard } from './ApplicationFormCard'
import { useEffect, useState } from 'react'
import { Loading } from '../../Loading'
import { ApplicationFileList } from './ApplicationFileList'

import type { UploadFileData } from '../../../hooks'
import { useApplicationFormContext } from './ApplicationForm'
import { FileData } from 'applications-types-lib'

type StatusAndCitizenshipFormFields = {
  passportNumber: string
  passportExpiryDate: string
  passportCountryOfIssue: string
  passportFiles: Array<{
    id: string
    file: File
  }>
  immigrationStatus: string
  immigrationFiles: Array<{
    id: string
    file: File
  }>
}

const { Form, Field, useFieldValues } = createForm<StatusAndCitizenshipFormFields>()

type StatusAndCitizenshipTabProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
}

export function StatusAndCitizenshipTab(props: StatusAndCitizenshipTabProps) {
  // TODO: Uncomment when FileUpload component support download
  // const { applicationFiles: passportFiles, isLoadingApplicationFiles: isLoadingPassportFiles } =
  //   useGetFileObjectFromApplicationFiles({ application: props.application, fileType: 'passport' })
  // const {
  //   applicationFiles: immigrationFiles,
  //   isLoadingApplicationFiles: isLoadingImmigrationFiles,
  // } = useGetFileObjectFromApplicationFiles({
  //   application: props.application,
  //   fileType: 'immigration',
  // })
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application?.id,
  })

  const { clearFiles, files, setFiles } = useApplicationFormContext()
  useEffect(() => {
    setFiles(
      getFilesOfType(['passport', 'immigration'], props.application?.attributes?.files as FileData),
    )
  }, [])

  const passportInfo = props.application?.attributes?.statusAndCitizenship?.passportInformation
  const destinationCountry = 'CA' //Mock data for demo
  const destinationCountryName = COUNTRY_NAMES[destinationCountry]

  // TODO: Uncomment when FileUpload component support download
  // if (isLoadingPassportFiles || isLoadingImmigrationFiles) {
  //   return <Loading />
  // }

  return (
    <Flex grow={1} direction="column">
      <Form
        defaultValues={{
          passportNumber: passportInfo?.passportNumber || '',
          passportExpiryDate: passportInfo?.passportExpiry
            ? convertTimelessDateStrToLocalDate(passportInfo.passportExpiry).toISOString()
            : '',
          passportCountryOfIssue: passportInfo?.passportCountry || '',
          passportFiles: [],
          // TODO - What is this status?
          immigrationStatus: '',
          immigrationFiles: [],
        }}
        onSubmit={data => {
          if (props.disabled) {
            props.onSuccess()
          } else {
            updateApplication(
              {
                attributes: {
                  statusAndCitizenship: {
                    passportInformation: {
                      passportNumber: data.passportNumber,
                      passportExpiry: data.passportExpiryDate?.substring(0, 10),
                      passportCountry: (data.passportCountryOfIssue as CountryIsoCode) || null,
                    },
                  },
                  files,
                },
              },
              {
                onSuccess: response => {
                  clearFiles()
                  props.onSuccess(response)
                },
                onError: props.onError,
              },
            )
          }
        }}
      >
        <ApplicationFormCard
          cardNumber={3}
          title={`🌎  Citizenship and ${destinationCountryName || 'Destination Country'} Status`}
          isLoading={isUpdatingApplication}
          disabled={props.disabled}
        >
          <StatusAndCitizenshipTabFields
            application={props.application}
            disabled={props.disabled}
            destinationCountryName={destinationCountryName}
          />
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}

type StatusAndCitizenshipTabFieldsProps = {
  application: RawApplicationResponse['data'] | null
  destinationCountryName: string
  disabled?: boolean
}

function StatusAndCitizenshipTabFields(props: StatusAndCitizenshipTabFieldsProps) {
  const { passportFiles, immigrationFiles, immigrationStatus } = useFieldValues([
    'passportFiles',
    'immigrationFiles',
    'immigrationStatus',
  ])
  const { addFile, deleteFile } = useApplicationFormContext()
  const { uploadFile } = useUploadFile({
    id: props.application?.id || '',
  })
  const [hasValidVisa, setValidVisa] = useState(false)

  const maxDate = new Date()
  const minDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() + 10)
  minDate.setFullYear(minDate.getFullYear() - 3)

  const passportUploadedFiles = Object.values(props.application?.attributes?.files || {}).some(
    file => file?.type === 'passport',
  )
  const immigrationUploadedFiles = Object.values(props.application?.attributes?.files || {}).some(
    file => file?.type === 'immigration',
  )

  return (
    <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
      <Flex basis="100%">
        <Heading variant="titleS" level={3}>
          Citizenship Information
        </Heading>
      </Flex>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={TextInput}
          label="Passport number"
          name="passportNumber"
          disabled={props.disabled}
          required={!props.disabled ? 'Passport number is required' : false}
        />
      </Flex.Item>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={DatePicker}
          label="Passport expiry date"
          name="passportExpiryDate"
          maxDate={maxDate.toISOString()}
          minDate={minDate.toISOString()}
          disabled={props.disabled}
          required={!props.disabled ? 'Passport expiry date is required' : false}
        />
      </Flex.Item>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={Combobox}
          label="Country of issue"
          name="passportCountryOfIssue"
          size="md"
          placeholder="Select"
          disabled={props.disabled}
          required={!props.disabled ? 'Country of issue is required' : false}
        >
          {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
            <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
          ))}
        </Field>
      </Flex.Item>
      <Flex gap={4} direction="column" basis="100%">
        {!props.disabled ? (
          <Field
            as={FileUpload}
            label="Add your passport document below, supported file formats: PDF, JPEG, PNG"
            allowedFileTypes={['.JPEG', '.JPG', '.PDF', '.PNG', '.jpeg', '.jpg', '.pdf', '.png']}
            name="passportFiles"
            disabled={props.disabled}
            required={!props.disabled && !passportUploadedFiles ? 'Passport is required' : false}
            uploadFile={(
              file: File,
              {
                onSuccess,
                onError,
              }: {
                onSuccess: (fileId: string) => void
                onError: (errorMessage?: string) => void
              },
            ) => {
              uploadFile({
                file: file,
                fileType: 'passport',
                onSuccess: (fileId: string, uploadFileData: UploadFileData) => {
                  addFile(fileId, uploadFileData)
                  onSuccess(fileId)
                },
                onError,
              })
            }}
            onRemoveFile={(fileInfo: FileInfo) => deleteFile(fileInfo.id)}
          />
        ) : null}
        {props.application ? (
          <ApplicationFileList
            application={props.application}
            disabled={props.disabled}
            documents={passportFiles}
            fileType="passport"
          />
        ) : null}
      </Flex>
      <Flex basis="100%" direction="column">
        <Heading variant="titleS" level={3}>
          {props.destinationCountryName || 'Destination Country'} Status Information
        </Heading>
        <Text variant="bodyS">
          Do you have a valid visa/permit in{' '}
          {props.destinationCountryName || 'the destination country'}?
        </Text>
      </Flex>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={Select}
          label="Status"
          name="immigrationStatus"
          appearance="styled"
          disabled={props.disabled}
          onChange={v => {
            setValidVisa(v !== 'none')
          }}
        >
          <Select.Option label="I don't have this" value="none" />
          <Select.Option label="Study Permit" value="studypermit" />
          <Select.Option label="Work Permit" value="workpermit" />
          <Select.Option label="Visitor/Tourist Visa" value="visa" />
          <Select.Option label="Other" value="other" />
        </Field>
      </Flex.Item>
      {!props.disabled && hasValidVisa ? (
        <Flex gap={4} direction="column" basis="100%">
          <Field
            as={FileUpload}
            label="Add your visa, study permit, or supporting document(s) below"
            name="immigrationFiles"
            multiple
            required={
              !props.disabled &&
              !immigrationUploadedFiles &&
              Boolean(immigrationStatus && immigrationStatus !== 'none')
                ? 'Documents are required'
                : false
            }
            uploadFile={(
              file: File,
              {
                onSuccess,
                onError,
              }: {
                onSuccess: (fileId: string) => void
                onError: (errorMessage?: string) => void
              },
            ) => {
              uploadFile({
                file: file,
                fileType: 'immigration',
                onSuccess: (fileId: string, uploadFileData: UploadFileData) => {
                  addFile(fileId, uploadFileData)
                  onSuccess(fileId)
                },
                onError,
              })
            }}
            onRemoveFile={(fileInfo: FileInfo) => deleteFile(fileInfo.id)}
          />
          {props.application ? (
            <ApplicationFileList
              application={props.application}
              disabled={props.disabled}
              documents={immigrationFiles}
              fileType="immigration"
            />
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  )
}
