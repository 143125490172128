import styled from '@emotion/styled'
import { Global } from '@emotion/react'
import { Button, Drawer, Flex } from '@applyboard/crystal-ui'
import { LogoutOutlineIcon } from '@applyboard/ui-icons'
import { NavBarItem } from './NavBarItem'
import { useAuthManager, useScreenResize } from '../../hooks'
import { MenuItemsProps } from './types'

export function MobileSidebar(props: {
  open: boolean
  onOpenChange: (open: boolean) => void
  menuItems: Array<MenuItemsProps>
}) {
  const { signout } = useAuthManager()

  useScreenResize(() => {
    if (window.innerWidth >= 600) {
      props.onOpenChange(false)
    }
  })

  return (
    <Flex hideAbove="sm" as="aside">
      <Global
        styles={{
          'aside[class*="Drawer"]': {
            borderRadius: 8,
            minHeight: '70vh',
            bottom: 0,
          },
          'aside[class*="Drawer"] > [class*="DrawerBody"]': {
            display: 'flex',
            borderRadius: 8,
          },
        }}
      />
      <Drawer
        open={props.open}
        onOpenChange={props.onOpenChange}
        placement="bottom"
        aria-label="Menu"
      >
        <MobileSidebarContainer>
          <Flex direction="column" grow={1} p={2} justify="between">
            <Flex direction="column">
              {props.menuItems.map(item => (
                <NavBarItem key={item.id} {...item}>
                  {props.open ? item.label : undefined}
                </NavBarItem>
              ))}
            </Flex>
            <Button
              leadIcon={LogoutOutlineIcon}
              width="fill"
              intent="primary"
              emphasis="transparent"
              aria-label="Sign Out"
              onClick={() => {
                signout()
              }}
            >
              {props.open ? 'Sign Out' : undefined}
            </Button>
          </Flex>
        </MobileSidebarContainer>
      </Drawer>
    </Flex>
  )
}

const MobileSidebarContainer = styled.div(() => ({
  display: 'flex',
  flexGrow: 1,
  'a > div': {
    justifyContent: 'flex-start',
  },
}))
