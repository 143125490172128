import { IRequest } from './IRequest'

export class BaseRequest implements IRequest {
  public readonly body: object | null
  public readonly url: string
  public readonly headers: Headers
  public readonly method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'

  constructor(
    url: string,
    options: {
      body?: object
      headers?: Record<string, string>
      method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'
    } = {},
  ) {
    this.url = url
    this.headers = this.getHeaders(options.headers)
    this.method = options.method || 'GET'
    this.body = options.body || null
  }

  private getHeaders(headers?: Record<string, string>): Headers {
    if (!headers) return new Headers()
    return new Headers(headers)
  }
}
