import { useAuthQuery } from './auth'
import { request } from '../utils/request'
import { ProfileResource } from '@backend/students-api/resources/profile'

type GetProfileProps = {
  id?: string
}

type RawProfilesResponse = {
  data: ProfileResource
}

export function useGetProfile(props: GetProfileProps) {
  const { isLoading, data, isFetching } = useAuthQuery(
    ['profile'],
    async () => {
      return await request<RawProfilesResponse>(`/profiles/${props.id}`, {
        method: 'GET',
        isExpectedResponse: (res): res is RawProfilesResponse => res,
      })
    },
    {
      enabled: !!props.id,
    },
  )

  return {
    isLoadingProfile: isLoading,
    isFetchingProfile: isFetching,
    profile: data?.data || null,
  }
}
