import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CrystalProvider } from '@applyboard/crystal-ui'
import { ProtectedRoute } from './components/ProtectedRoute'
import {
  ApplicationsListPage,
  CreateApplicationOverviewPage,
  CreateApplicationPage,
  OnboardingPage,
  LoginPage,
  NotFoundPage,
  ProfilePage,
  SignUpConfirmationPage,
  SignUpPage,
  ViewApplicationPage,
} from './pages'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CrystalProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<ApplicationsListPage />} />
              <Route path="/onboarding" element={<OnboardingPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/applications/overview" element={<CreateApplicationOverviewPage />} />
              <Route path="/applications/new" element={<CreateApplicationPage />} />
              <Route path="/applications/:id" element={<ViewApplicationPage />} />
            </Route>
            <Route element={<ProtectedRoute isSignupLoginRoute />}>
              <Route path="/signup/:username/confirm" element={<SignUpConfirmationPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/login" element={<LoginPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </CrystalProvider>
    </QueryClientProvider>
  )
}

export default App
