import { Button, Flex, Heading, ProgressIndicator } from '@applyboard/crystal-ui'
import { ArrowLeftOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import { useGetApplication } from '../../hooks'
import {
  ApplicationForms,
  ApplicationHeader,
  ApplicationTimeline,
} from '../../components/Application'

export function ViewApplicationPage() {
  const { id } = useParams()
  const { isLoadingApplication, application } = useGetApplication({ id })
  const [currentStep, setCurrentStep] = useState(1)
  const selectedTab = currentStep

  if (isLoadingApplication) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  if (!application) {
    return <Navigate to="/not-found" />
  }

  return (
    <NavBars largePage>
      <Flex direction="column" gap={10}>
        <Flex hideAbove="md" pb={3} gap={10}>
          <Button
            aria-label="Return to Program Selection"
            leadIcon={ArrowLeftOutlineIcon}
            emphasis="highlighted"
            disabled={currentStep === 1}
            onClick={() => {
              setCurrentStep(step => step - 1)
            }}
          />
          <ProgressionWrapper>
            <ProgressIndicator
              variant="linear"
              label="Completion"
              size="lg"
              total={100}
              value={(selectedTab - 1) * (100 / 6)}
            />
          </ProgressionWrapper>
        </Flex>

        <Flex hideBelow={selectedTab === 7 ? 'sm' : undefined} align="center" gap={4}>
          <img src="/application-illustration.svg" alt="New Application" />
          <Heading level={1} variant="headlineL">
            View Application
          </Heading>
        </Flex>
        <Flex hideBelow={selectedTab === 7 ? 'sm' : undefined} direction="column" grow={1}>
          <ApplicationHeader application={application} />
        </Flex>
        <Flex gap={12}>
          <ApplicationTimeline
            selectedTab={selectedTab}
            setSelectedTab={tab => setCurrentStep(tab)}
            application={application}
          />
          <ApplicationForms
            disabled
            selectedTab={selectedTab}
            application={application}
            onSuccess={() => {
              if (selectedTab <= 6) {
                setCurrentStep(currentStep + 1)
              }
            }}
          />
        </Flex>
      </Flex>
    </NavBars>
  )
}

const ProgressionWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  '> div': {
    width: '100%',
    '> div:first-of-type': {
      display: 'none',
    },
  },
})
