export class NetworkError extends Error {
  name = 'NetworkError'
  status?: number

  constructor() {
    super('A network error occurred. There may be a problem with the internet connection')
  }
}

export class HttpError extends Error {
  name = 'HttpError'
  status: number

  constructor(status: number, message: string) {
    super(message)
    this.status = status
  }
}

export class UnexpectedResponseError extends Error {
  name = 'UnexpectedResponseError'
  status: number

  constructor(status: number, message: string) {
    super(message)
    this.status = status
  }
}

export type GenericError = NetworkError | HttpError | UnexpectedResponseError
