import { Flex, Spinner } from '@applyboard/crystal-ui'

type LoadingProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

export function Loading({ size = 'xl' }: LoadingProps) {
  return (
    <Flex align="center" justify="center">
      <Spinner size={size} />
    </Flex>
  )
}
