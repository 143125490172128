import styled from '@emotion/styled'
import { Box, ButtonLink, Card, Carousel, Flex, Text } from '@applyboard/crystal-ui'

export function DesktopOnboarding() {
  const onboardingCards = [
    {
      key: crypto.randomUUID(),
      title: 'Personal Information',
      text: 'Share some basic info about yourself to set up your profile before starting an application.',
      imageComponent: <CardImage src="/student-working.svg" alt="student working" />,
      actionComponent: (
        <ButtonLink href="/profile" intent="primary">
          Create Profile
        </ButtonLink>
      ),
    },
    {
      key: crypto.randomUUID(),
      title: 'Start Application',
      text: 'Find your program of interest and select your intake term to kick off your application.',
      imageComponent: <CardImage src="/application-clipboard.svg" alt="application form" />,
    },
    {
      key: crypto.randomUUID(),
      title: 'Submit Application',
      text: 'Complete your online application with the required information and documents.',
      imageComponent: <CardImage src="/application-documents.svg" alt="application documents" />,
    },
  ]

  return (
    <Box pr={14}>
      <Carousel aria-label={'Onboarding actions'} itemsPerSlide={1} navButtonPlacement="outside">
        {onboardingCards.map((onboardingCard, index) => (
          <Card key={onboardingCard.key} emphasis="highlighted">
            <Flex gap={3} py={20} px={8} align="center" justify="between">
              <Flex direction="column" gap={4}>
                <StepText>0{index + 1}</StepText>
                <Text variant="displayS" as="p">
                  {onboardingCard.title}
                </Text>
                <Box pt={2} pb={6}>
                  <Text variant="bodyL" as="p">
                    {onboardingCard.text}
                  </Text>
                </Box>
                {onboardingCard.actionComponent ? (
                  <Flex.Item align="start">{onboardingCard.actionComponent}</Flex.Item>
                ) : null}
              </Flex>
              <Box hideBelow="md">{onboardingCard.imageComponent}</Box>
            </Flex>
          </Card>
        ))}
      </Carousel>
    </Box>
  )
}

const StepText = styled.div(() => ({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '96px',
  lineHeight: '117.02px',
  color: '#1E6DEB',
}))

const CardImage = styled.img({
  maxWidth: '100%',
})
