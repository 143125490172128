import { ButtonLink, Divider, Flex, Heading, Text } from '@applyboard/crystal-ui'
import { ArrowRightOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { NavBars } from '../../components/NavBars'
import { up } from '../../utils'

export function CreateApplicationOverviewPage() {
  return (
    <NavBars>
      <Flex direction="column" gap={{ xs: 14, sm: 10 }}>
        <Heading level={1} variant="headlineL" align={{ xs: 'center', sm: 'left' }}>
          Application Overview
        </Heading>
        <Flex direction="column">
          <Grid>
            <OverviewRow
              row={1}
              title="Program Selection"
              text="Find a program and select the intake start date."
              image="/program-selection-illustration.svg"
            />
            <OverviewRow
              row={2}
              title="Fill Application Details"
              text="Have all required documents such as passport, language tests, and academic transcripts ready before you begin."
              image="/fill-application-illustration.svg"
            />
            <OverviewRow
              row={3}
              title="Submit Application"
              text="Make sure to always save your application progress before you submit."
              image="/submit-application-illustration.svg"
            />
          </Grid>
          <Flex justify="end" pt={{ xs: 0, sm: 8 }}>
            <ButtonLink
              href="/applications/new"
              trailIcon={ArrowRightOutlineIcon}
              intent="primary"
              width={{ xs: 'fill', sm: 'hug' }}
            >
              Start Application
            </ButtonLink>
          </Flex>
        </Flex>
      </Flex>
    </NavBars>
  )
}

type OverviewRowProps = {
  row: number
  title: string
  text: string
  image: string
}

function OverviewRow(props: OverviewRowProps) {
  return (
    <>
      <NumberGridItem row={props.row}>
        <Number>0{props.row}</Number>
      </NumberGridItem>
      <TextGridItem row={props.row}>
        <Flex direction="column" gap={2} pt={2}>
          <Text variant="titleL">{props.title}</Text>
          <Text variant="bodyL">{props.text}</Text>
        </Flex>
      </TextGridItem>
      <ImageGridItem row={props.row}>
        <img src={props.image} alt={props.title} />
      </ImageGridItem>
      <DividerGridItem row={props.row}>
        <Divider />
      </DividerGridItem>
    </>
  )
}

const Number = styled.div(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontWeight: '600',
  fontSize: '5rem',
  lineHeight: '5rem',
  color: theme.colors.actionPrimary,
}))

const Grid = styled.div({
  display: 'grid',
  gridTemplateColumns:
    '[number-start] 100px [content-start] 1fr [illustration-start] auto [column-end]',
  gridTemplateRows:
    '[row-1] max-content [divider-1] max-content [row-2] max-content [divider-2] max-content [row-3] max-content [divider-3] max-content',
  alignItems: 'center',
})

const NumberGridItem = styled.div<{ row: number }>(props => ({
  gridArea: `row-${props.row} / number-start / divider-${props.row} / content-start`,
}))

const TextGridItem = styled.div<{ row: number }>(props => ({
  paddingLeft: 16,
  gridArea: `row-${props.row} / content-start / divider-${props.row} / illustration-start`,
  [up('sm')]: {
    paddingLeft: 24,
    paddingRight: 40,
    gridArea: `row-${props.row} / content-start / divider-${props.row} / illustration-start`,
  },
}))

const ImageGridItem = styled.div<{ row: number }>(props => ({
  display: 'none',
  [up('sm')]: {
    gridArea: `row-${props.row} / illustration-start / divider-${props.row} / column-end`,
    display: 'block',
  },
}))

const DividerGridItem = styled.div<{ row: number }>(props => ({
  gridArea: `divider-${props.row} / number-start / row-${props.row + 1} / column-end`,
}))
