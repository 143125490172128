import {
  Button,
  Checkbox,
  createForm,
  Flex,
  Heading,
  Link,
  Text,
  TextInput,
  useToasts,
} from '@applyboard/crystal-ui'
import { useSignUp } from '../../hooks'
import { emailValidate } from '../../utils/fieldValidations'

type SignUpFormFields = {
  givenName: string
  familyName: string
  email: string
  agreement: boolean
}

const { Form, Field, useFieldValues } = createForm<SignUpFormFields>()

type SignUpFormProps = {
  onSuccess: (id: string) => void
}

export function SignUpForm(props: SignUpFormProps) {
  const toast = useToasts()
  const { isSigningUp, signUp } = useSignUp()

  return (
    <>
      <Flex gap={10} direction="column">
        <Flex justify="center">
          <Heading level={1} variant="headlineM">
            Create account
          </Heading>
        </Flex>
        <Form
          defaultValues={{
            givenName: '',
            familyName: '',
            email: '',
            agreement: false,
          }}
          onSubmit={({ givenName, familyName, email }) => {
            signUp(
              { givenName, familyName, email },
              {
                onSuccess: response => {
                  props.onSuccess(response.data.id)
                },
                onError: err => {
                  if (err instanceof Error) {
                    toast.negative(new Error(err.message))
                  }
                },
              },
            )
          }}
        >
          <Flex gap={{ xs: 6, sm: 4 }} direction="column">
            <Flex gap={6} direction={{ xs: 'column', sm: 'row' }}>
              <Flex.Item grow={1}>
                <Field
                  as={TextInput}
                  label="Given Name"
                  name="givenName"
                  validate={(value: string) => {
                    return !!value.length || 'This field is required'
                  }}
                />
              </Flex.Item>
              <Flex.Item grow={1}>
                <Field as={TextInput} label="Family Name" name="familyName" />
              </Flex.Item>
            </Flex>
            <Field
              as={TextInput}
              label="Email"
              name="email"
              validate={(value: string) => {
                return emailValidate(value) || 'This email is invalid.'
              }}
            />
            <Field
              as={Checkbox}
              label={
                <Text>
                  I have read and agree to the <Link href="#">Terms and Conditions</Link> and the{' '}
                  <Link href="#">Privacy and Cookies Policy*</Link>.
                </Text>
              }
              name="agreement"
            />
            <Flex justify="center" pt={{ xs: 4, sm: 6 }}>
              <SubmitButton loading={isSigningUp} />
            </Flex>
          </Flex>
        </Form>
      </Flex>
      <Flex justify="center" pt={{ xs: 8, sm: 4 }}>
        <Text>
          Already have an account? <Link href="/login">Login</Link>
        </Text>
      </Flex>
    </>
  )
}

type SubmitButtonProps = {
  loading: boolean
}

function SubmitButton(props: SubmitButtonProps) {
  const { givenName, email, agreement } = useFieldValues(['givenName', 'email', 'agreement'])

  return (
    <Button
      intent="primary"
      width="fill"
      type="submit"
      disabled={!givenName.length || !emailValidate(email) || !agreement}
      loading={props.loading}
    >
      Next
    </Button>
  )
}
