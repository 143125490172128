import { Button, Dialog, Flex, Heading, Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScreenResize } from '../../../hooks'
import { breakpoints } from '../../../utils'

export function SubmitConfirmation(props: {
  showSubmitConfirmation: boolean
  setShowSubmitConfirmation: Dispatch<SetStateAction<boolean>>
}) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(window.innerWidth >= breakpoints.sm)

  useScreenResize(() => {
    setShowModal(window.innerWidth >= breakpoints.sm)
  })

  if (!props.showSubmitConfirmation) return null

  return (
    <>
      <Flex hideAbove="sm" direction="column" gap={10} grow={1}>
        <Heading level={1} variant="headlineL" align="center">
          Application Submitted
        </Heading>
        <Flex direction="column" gap={4} align="center">
          <>
            <Image alt="congratulations" src="/tada-illustration.svg" />
            <Flex direction="column" align="center">
              <Text>You’re a rockstar!</Text>
              <Text>Your application is ready for submission.</Text>
            </Flex>
          </>
        </Flex>
        <Flex pt={1} direction="column" gap={4}>
          <Button
            onClick={() => {
              props.setShowSubmitConfirmation(false)
              navigate('/')
            }}
            width="fill"
            intent="primary"
          >
            Thanks
          </Button>
        </Flex>
      </Flex>

      <Dialog
        onOpenChange={open => {
          props.setShowSubmitConfirmation(open)
        }}
        open={props.showSubmitConfirmation && showModal}
      >
        <Dialog.Heading>Application Submitted</Dialog.Heading>
        <Dialog.Content>
          <Flex direction="column" align="center" gap={6}>
            <Image alt="test" src="/tada-illustration.svg" />
            <Text>You’re a rockstar! Your application was submitted successfully</Text>
          </Flex>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            onClick={() => {
              props.setShowSubmitConfirmation(false)
              navigate('/')
            }}
            width="fill"
            intent="primary"
          >
            Thanks
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}

const Image = styled.img({
  width: '100%',
  maxWidth: 300,
})
