import { isEmpty } from 'lodash'
import { RawApplicationResponse } from '../../../hooks'

enum TimelineTabs {
  PERSONAL_INFORMATION = 1,
  CONTACT_INFORMATION = 2,
  STATUS_AND_CITIZENSHIP = 3,
  EDUCATION_HISTORY = 4,
  LANGUAGE_PROFICIENCY = 5,
  OTHER_REQUIRED_DOCUMENTS = 6,
}

type isTabCompletedProps = {
  index: number
  application: RawApplicationResponse['data'] | null
}

export function isTabCompleted(props: isTabCompletedProps) {
  if (!props.application) {
    return false
  }

  switch (props.index) {
    case TimelineTabs.PERSONAL_INFORMATION:
      return (
        !isEmpty(props.application.attributes?.personalInformation?.basicPersonalInformation) ||
        !isEmpty(props.application.attributes?.personalInformation?.addresses)
      )
    case TimelineTabs.CONTACT_INFORMATION:
      return (
        !isEmpty(props.application.attributes?.personalInformation?.contactInformation) ||
        !isEmpty(props.application.attributes?.personalInformation?.emergencyContact)
      )
    case TimelineTabs.STATUS_AND_CITIZENSHIP:
      return !isEmpty(props.application.attributes?.statusAndCitizenship?.passportInformation)
    case TimelineTabs.EDUCATION_HISTORY:
      return !isEmpty(props.application.attributes?.education)
    case TimelineTabs.LANGUAGE_PROFICIENCY:
      return !isEmpty(props.application.attributes?.englishProficiency)
    case TimelineTabs.OTHER_REQUIRED_DOCUMENTS:
      return Object.values(props.application.attributes?.files || {}).find(file => {
        return typeof file?.type === 'string' && file?.type === 'additional_documents'
      })
  }

  return false
}
