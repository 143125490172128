import { Card, Box, Table, ButtonLink, Text, Tag } from '@applyboard/crystal-ui'
import { EyeOutlineIcon } from '@applyboard/ui-icons'
import { RawListApplicationsResponse, ApplicationState } from '../../hooks/useListApplications'

type ApplicationsTableProps = {
  applications: RawListApplicationsResponse['data'] | null
}

export function ApplicationsTable(props: ApplicationsTableProps) {
  return (
    <Box hideBelow="md">
      <Card emphasis="elevated">
        <Box p={4}>
          <Table
            columns={[
              {
                accessorKey: 'program',
                header: 'Program Name',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  if (!application.attributes?.programsSelected?.[0]?.programInformation) {
                    return (
                      <Box p={4}>
                        <Text>No program selected</Text>
                      </Box>
                    )
                  }

                  return (
                    <Box p={4}>
                      <Text>
                        {application.attributes.programsSelected[0].programInformation
                          ?.programTitle ||
                          `Program ${application.attributes.programsSelected[0].programInformation.programId}`}
                      </Text>
                    </Box>
                  )
                },
              },
              {
                accessorKey: 'intake',
                header: 'Intake Term',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  return (
                    <Box p={4}>
                      <Text>
                        {application.attributes?.programsSelected?.[0]?.programInformation
                          ?.intakeInformation?.intakeTerm || '--'}
                      </Text>
                    </Box>
                  )
                },
              },
              {
                accessorKey: 'status',
                header: 'Status',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  return (
                    <Box p={4}>
                      <Tag
                        intent={
                          application.attributes?.applicationState === ApplicationState.DRAFT
                            ? 'warning'
                            : 'positive'
                        }
                      >
                        {application.attributes?.applicationState === ApplicationState.DRAFT
                          ? 'Draft'
                          : 'Submitted'}
                      </Tag>
                    </Box>
                  )
                },
              },
              {
                accessorKey: 'options',
                header: '',
                cell: (application: RawListApplicationsResponse['data'][0]) => {
                  // TODO: In Progress will be updated to Draft or In Draft.
                  if (application.attributes?.applicationState === ApplicationState.DRAFT) {
                    return (
                      <ButtonLink
                        href={`/applications/new?applicationId=${application.id}`}
                        intent="primary"
                      >
                        Complete Application
                      </ButtonLink>
                    )
                  }

                  return (
                    <ButtonLink
                      href={`/applications/${application.id}`}
                      intent="primary"
                      emphasis="outlined"
                      leadIcon={EyeOutlineIcon}
                    >
                      View Application
                    </ButtonLink>
                  )
                },
              },
            ]}
            data={props?.applications || []}
          />
        </Box>
      </Card>
    </Box>
  )
}
