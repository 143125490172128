import { Flex, Heading, Notice, ProgressIndicator, useToasts } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import {
  RawApplicationResponse,
  useCreateApplication,
  useGetApplication,
  useSubmitApplication,
  useUpdateApplication,
} from '../../hooks'
import {
  ApplicationForms,
  ApplicationHeader,
  ApplicationTimeline,
} from '../../components/Application'
import { SelectProgram } from './SelectProgram'
import { GenericError } from '../../utils'
import { SubmitConfirmation } from './SubmitConfirmation'
import { SubmitApprovalDialog } from './SubmitApprovalDialog'
import { BackToApplication } from './BackToApplication'

export function CreateApplicationPage() {
  const toast = useToasts()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const currentApplicationId = searchParams.get('applicationId') || ''

  const { isLoadingApplication, application } = useGetApplication({
    id: currentApplicationId,
  })

  const { isCreatingApplication, createApplication } = useCreateApplication()
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: currentApplicationId,
  })
  const { isSubmittingApplication, submitApplication } = useSubmitApplication({
    id: currentApplicationId,
  })

  const [showSubmitApprovalDialog, setShowSubmitApprovalDialog] = useState(false)
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false)

  const [error, setError] = useState('')

  const [currentStep, setCurrentStep] = useState(1)
  const selectedTab = currentStep - 1

  const handleUpdateApplication = (programId: string, intakeId: string) => {
    updateApplication(
      {
        attributes: {
          programsSelected: [
            {
              priority: 0,
              programInformation: {
                programId: programId,
                intakeInformation: {
                  intakeId: intakeId,
                },
              },
            },
          ],
        },
      },
      {
        onSuccess: response => {
          setSearchParams({ applicationId: response.data.id })
          setCurrentStep(step => step + 1)
          queryClient.setQueryData(['applications', response.data.id], {
            data: {
              ...(application || {}),
              attributes: response.data.attributes,
            },
          })
        },
        onError: err => {
          if (err instanceof Error) {
            toast.negative(new Error(err.message))
          }
        },
      },
    )
  }

  const handleCreateApplication = (programId: string, intakeId: string) => {
    createApplication(
      {
        programId: programId,
        intakeId: intakeId,
      },
      {
        onSuccess: response => {
          queryClient.setQueryData(['applications', response.data.id], response)
          setCurrentStep(step => step + 1)
          setSearchParams({ applicationId: response.data.id })
        },
        onError: err => {
          if (err instanceof Error) {
            toast.negative(new Error(err.message))
          }
        },
      },
    )
  }
  const handleSubmitApplication = (
    successCallback: (response: RawApplicationResponse) => void,
    errorCallback: (err: GenericError) => void,
  ) => {
    submitApplication(undefined, {
      onSuccess: response => {
        successCallback(response)
      },
      onError: err => {
        if (err instanceof Error) {
          errorCallback(err)
        }
      },
    })
  }

  const handleSubmitConfirmation = () => {
    handleSubmitApplication(
      (response: RawApplicationResponse) => {
        queryClient.setQueryData(['applications', response.data.id], response)
        setShowSubmitApprovalDialog(false)
        setShowSubmitConfirmation(true)
      },
      (err: GenericError) => {
        setShowSubmitApprovalDialog(false)
        setShowSubmitConfirmation(false)
        setError(err.message)
      },
    )
  }

  const handleSubmitApplicationTrigger = () => {
    setShowSubmitApprovalDialog(true)
  }

  if (currentApplicationId && isLoadingApplication) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  if (currentStep <= 1) {
    return (
      <NavBars maxWidth={680}>
        <BackToApplication />
        <SelectProgram
          application={application}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isLoading={isCreatingApplication || isUpdatingApplication}
          onSubmit={({ program, intake }) => {
            if (currentStep === 1 && program && intake) {
              if (application?.attributes?.programsSelected?.length) {
                handleUpdateApplication(program, intake)
              } else {
                handleCreateApplication(program, intake)
              }
            }
          }}
        />
      </NavBars>
    )
  }

  return (
    <NavBars largePage>
      <BackToApplication />
      <Flex direction="column" gap={10}>
        <Flex hideAbove="md" pb={3}>
          <ProgressionWrapper>
            <ProgressIndicator
              variant="linear"
              label="Completion"
              size="lg"
              total={100}
              value={(selectedTab - 1) * (100 / 6)}
            />
          </ProgressionWrapper>
        </Flex>

        <Flex
          hideBelow={selectedTab === 7 && showSubmitApprovalDialog ? 'sm' : undefined}
          align="center"
          gap={4}
        >
          <img src="/application-illustration.svg" alt="New Application" />
          <Heading level={1} variant="headlineL">
            New Application
          </Heading>
        </Flex>
        <Flex
          hideBelow={selectedTab === 7 && showSubmitApprovalDialog ? 'sm' : undefined}
          direction="column"
          grow={1}
        >
          <ApplicationHeader application={application} />
        </Flex>
        {error ? <Notice intent="negative">{error}</Notice> : null}
        <Flex gap={12}>
          <ApplicationTimeline
            selectedTab={selectedTab}
            setSelectedTab={tab => setCurrentStep(tab + 1)}
            application={application}
          />
          <Flex grow={1} hideBelow={showSubmitApprovalDialog ? 'sm' : undefined}>
            <ApplicationForms
              selectedTab={selectedTab}
              application={application}
              onSuccess={response => {
                setError('')

                if (response && selectedTab <= 6) {
                  queryClient.invalidateQueries({ queryKey: ['applications', response.data.id] })
                  setCurrentStep(currentStep + 1)
                }
              }}
              onError={err => {
                setError(err)
              }}
              submitApplication={handleSubmitApplicationTrigger}
            />
          </Flex>
          <SubmitApprovalDialog
            showSubmitApprovalDialog={showSubmitApprovalDialog}
            setShowSubmitApprovalDialog={setShowSubmitApprovalDialog}
            onSubmitConfirmation={handleSubmitConfirmation}
            isLoading={isSubmittingApplication}
          />
          <SubmitConfirmation
            showSubmitConfirmation={showSubmitConfirmation}
            setShowSubmitConfirmation={setShowSubmitConfirmation}
          />
        </Flex>
      </Flex>
    </NavBars>
  )
}

const ProgressionWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  '> div': {
    width: '100%',
    '> div:first-of-type': {
      display: 'none',
    },
  },
})
