import jwt_decode from 'jwt-decode'
import { AuthStorage } from '../../utils'
import type { StudentPayload } from '@backend/students-auth-api/routes/oauth2/token/POST/types'

export function useAuthToken() {
  function getAccessTokenPayload(): StudentPayload | null {
    const accessToken = AuthStorage.getAccessToken()

    if (!accessToken) return null
    try {
      return jwt_decode(accessToken) as StudentPayload
    } catch (e) {
      return null
    }
  }

  function isActiveSession(): boolean {
    const tokenPayload = getAccessTokenPayload()
    if (!tokenPayload?.exp) return false
    return Date.now() < tokenPayload.exp * 1000
  }

  return {
    getAccessTokenPayload,
    isActiveSession,
  }
}
