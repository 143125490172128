import { createForm, FileInfo, FileUpload, Flex, useToasts } from '@applyboard/crystal-ui'
import {
  getFilesOfType,
  RawApplicationResponse,
  UploadFileData,
  useUpdateApplication,
  useUploadFile,
} from '../../../hooks'
import { useGetFileObjectFromApplicationFiles } from '../../../hooks/useGetFileObjectFromApplicationFiles'
import { Loading } from '../../Loading'
import { ApplicationFileList } from './ApplicationFileList'
import { ApplicationFormCard } from './ApplicationFormCard'
import { useApplicationFormContext } from './ApplicationForm'
import { useEffect } from 'react'
import { FileData } from 'applications-types-lib'

type AdditionalDocumentsFormFields = {
  documents: Array<{
    id: string
    file: File
  }>
}

const { Form, Field, useFieldValues } = createForm<AdditionalDocumentsFormFields>()

type AdditionalDocumentsTabProps = {
  disabled?: boolean
  selectedTab: number
  application: RawApplicationResponse['data'] | null
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
  submitApplication?: () => void
}

export function AdditionalDocumentsTab(props: AdditionalDocumentsTabProps) {
  // TODO: Uncomment when FileUpload component support download
  // const { applicationFiles, isLoadingApplicationFiles } = useGetFileObjectFromApplicationFiles({
  //   application: props.application,
  //   fileType: 'additional_documents',
  // })
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application?.id,
  })
  const { files, setFiles } = useApplicationFormContext()
  useEffect(() => {
    setFiles(
      getFilesOfType(['additional_documents'], props.application?.attributes?.files as FileData),
    )
  }, [])
  const toast = useToasts()

  // TODO: Uncomment when FileUpload component support download
  // if (isLoadingApplicationFiles) {
  //   return <Loading />
  // }

  return (
    <Flex grow={1} direction="column">
      <Form
        defaultValues={{
          // TODO: Uncomment when FileUpload component support download
          // documents: applicationFiles,
          documents: [],
        }}
        onSubmit={_ => {
          if (props.disabled) {
            props.onSuccess()
          } else {
            updateApplication(
              {
                attributes: {
                  files,
                },
              },
              {
                onSuccess: response => {
                  setFiles(
                    getFilesOfType(
                      ['additional_documents'],
                      response.data.attributes?.files as FileData,
                    ),
                  )
                  props.onSuccess(response)
                  toast.positive('Your application has been saved successfully.')
                },
                onError: props.onError,
              },
            )
          }
        }}
      >
        <ApplicationFormCard
          cardNumber={6}
          isLoading={isUpdatingApplication}
          title="📄  Other Required Documents"
          onSubmitApplication={() => {
            if (props.application) {
              props.onSuccess({ data: props.application })
            }
            if (props.submitApplication) {
              props.submitApplication()
            }
          }}
          disabled={props.disabled}
        >
          <Documents disabled={props.disabled} application={props.application} />
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}

type DocumentsProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
}

function Documents(props: DocumentsProps) {
  const { documents } = useFieldValues(['documents'])
  const { uploadFile } = useUploadFile({
    id: props.application?.id || '',
  })
  const { addFile, deleteFile } = useApplicationFormContext()

  return (
    <Flex pt={14} pb={documents.length ? 0 : 14} direction="column" gap={4}>
      {!props.disabled ? (
        <Field
          as={FileUpload}
          name="documents"
          multiple
          uploadFile={(
            file: File,
            {
              onSuccess,
              onError,
            }: {
              onSuccess: (fileId: string) => void
              onError: (errorMessage?: string) => void
            },
          ) => {
            uploadFile({
              file: file,
              fileType: 'additional_documents',
              onSuccess: (fileId: string, uploadFileData: UploadFileData) => {
                addFile(fileId, uploadFileData)
                onSuccess(fileId)
              },
              onError,
            })
          }}
          onRemoveFile={(fileInfo: FileInfo) => deleteFile(fileInfo.id)}
        />
      ) : null}

      {props.application ? (
        <ApplicationFileList
          application={props.application}
          disabled={props.disabled}
          documents={documents}
          fileType="additional_documents"
        />
      ) : null}
    </Flex>
  )
}
