import { useQueryClient } from '@tanstack/react-query'
import { useAuthMutation } from './auth'
import { request } from '../utils/request'
import { VersionedProfileResource } from '@backend/students-api/resources/profile'
import { UpdateProfileRequest } from '@backend/students-api/routes/profiles/{id}/PATCH/types'

type UpdateProfileProps = {
  id?: string
}

type UpdateProfileParams = UpdateProfileRequest['data']['attributes']

type RawProfilesResponse = {
  data: VersionedProfileResource
}

export function useUpdateProfile(props: UpdateProfileProps) {
  const queryClient = useQueryClient()

  const { isPending, mutate } = useAuthMutation(
    async (params: UpdateProfileParams) => {
      return await request<RawProfilesResponse>(`/profiles/${props.id}`, {
        method: 'PATCH',
        isExpectedResponse: (res): res is RawProfilesResponse => res,
        body: {
          data: {
            id: props.id,
            type: 'student_profile',
            attributes: {
              givenName: params?.givenName || null,
              familyName: params?.familyName || null,
              middleName: params?.middleName || null,
              dateOfBirth: params?.dateOfBirth?.substring(0, 10) || null,
              nationality: params?.nationality || null,
              gender: params?.gender || null,
            },
          },
        },
      })
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(['profile'], data)
      },
    },
  )

  return {
    isUpdatingProfile: isPending,
    updateProfile: mutate,
  }
}
