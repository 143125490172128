import { Card, ButtonLink, Tag, Flex, Heading, DescriptionList } from '@applyboard/crystal-ui'
import { EyeOutlineIcon } from '@applyboard/ui-icons'
import { RawListApplicationsResponse, ApplicationState } from '../../hooks/useListApplications'

type ApplicationsCardProps = {
  applications: RawListApplicationsResponse['data'] | null
}

export function ApplicationsCard(props: ApplicationsCardProps) {
  return (
    <Flex hideAbove="md" direction="column" gap={8} pt={6}>
      {props.applications?.map(application => (
        <Card key={application.id} emphasis="elevated">
          <Card.Header>
            <Heading level={2}>
              {!application.attributes?.programsSelected?.[0]?.programInformation?.programTitle
                ? `No program selected`
                : application.attributes.programsSelected[0].programInformation.programTitle}
            </Heading>
          </Card.Header>
          <DescriptionList aria-label={`Application #${application.id}`}>
            <DescriptionList.Item
              details={
                application.attributes?.programsSelected?.[0]?.programInformation?.intakeInformation
                  ?.intakeTerm || '--'
              }
              term="Intake Term"
            />
            <DescriptionList.Item
              details={
                <Tag
                  intent={
                    application.attributes?.applicationState === ApplicationState.DRAFT
                      ? 'warning'
                      : 'positive'
                  }
                >
                  {application.attributes?.applicationState === ApplicationState.DRAFT
                    ? 'Draft'
                    : 'Submitted'}
                </Tag>
              }
              term="Status"
            />
          </DescriptionList>
          <Card.Footer>
            {application.attributes?.applicationState === ApplicationState.DRAFT ? (
              <ButtonLink
                href={`/applications/new?applicationId=${application.id}`}
                intent="primary"
              >
                Complete Application
              </ButtonLink>
            ) : (
              <ButtonLink
                href={`/applications/${application.id}`}
                intent="primary"
                emphasis="outlined"
                leadIcon={EyeOutlineIcon}
              >
                View Application
              </ButtonLink>
            )}
          </Card.Footer>
        </Card>
      ))}
    </Flex>
  )
}
