import { Checkbox, createForm, Flex, Heading, Select, TextInput } from '@applyboard/crystal-ui'
import { RelationshipCode } from 'applications-types-lib'
import { isEmpty, omitBy, set } from 'lodash'
import { useGetProfile, useLoggedInUser } from '../../../hooks'
import { RawApplicationResponse } from '../../../hooks/useGetApplication'
import { useUpdateApplication } from '../../../hooks/useUpdateApplication'
import { emailValidate } from '../../../utils/fieldValidations'
import { Loading } from '../../Loading'
import { NavBars } from '../../NavBars'
import { ApplicationFormCard } from './ApplicationFormCard'

type ContactInformationFormFields = {
  phone: string
  alternatePhone: string
  email: string
  provideEmergencyContact: boolean
  emergencyName: string
  emergencyRelationship: string
  emergencyPhone: string
  emergencyEmail: string
}

const { Form, Field, useFieldValues } = createForm<ContactInformationFormFields>()

type ContactInformationTabProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
}

export function ContactInformationTab(props: ContactInformationTabProps) {
  const { username } = useLoggedInUser()
  const { isLoadingProfile, profile } = useGetProfile({ id: username })

  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application?.id,
  })

  if (isLoadingProfile) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  return (
    <Flex grow={1} direction="column">
      <Form
        defaultValues={{
          phone:
            props.application?.attributes?.personalInformation?.contactInformation?.phoneNumber ||
            '',
          alternatePhone:
            props.application?.attributes?.personalInformation?.contactInformation
              ?.alternatePhoneNumber || '',
          email: profile?.attributes.email || '',
          provideEmergencyContact: haveEmergencyContact(props.application),
          emergencyName:
            props.application?.attributes?.personalInformation?.emergencyContact?.name || '',
          emergencyRelationship:
            props.application?.attributes?.personalInformation?.emergencyContact?.relationship ||
            '',
          emergencyPhone:
            props.application?.attributes?.personalInformation?.emergencyContact?.phoneNumber || '',
          emergencyEmail:
            props.application?.attributes?.personalInformation?.emergencyContact?.email || '',
        }}
        onSubmit={data => {
          if (props.disabled) {
            props.onSuccess()
          } else {
            const personalInformation = {
              contactInformation: {
                phoneNumber: data.phone,
                alternatePhoneNumber: data.alternatePhone,
              },
            }

            if (data.provideEmergencyContact) {
              set(personalInformation, 'emergencyContact', {
                name: data.emergencyName,
                relationship: data.emergencyRelationship as RelationshipCode,
                email: data.emergencyEmail,
                phoneNumber: data.emergencyPhone,
              })
            } else {
              set(personalInformation, 'emergencyContact', null)
            }

            updateApplication(
              {
                attributes: {
                  personalInformation,
                },
              },
              {
                onSuccess: props.onSuccess,
                onError: props.onError,
              },
            )
          }
        }}
      >
        <ApplicationFormCard
          cardNumber={2}
          title="☎️  Contact Information"
          isLoading={isUpdatingApplication}
          disabled={props.disabled}
        >
          <ContactInformationFields disabled={props.disabled} application={props.application} />
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}

type ContactInformationFieldsProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
}

function ContactInformationFields(props: ContactInformationFieldsProps) {
  const { provideEmergencyContact } = useFieldValues(['provideEmergencyContact'])

  return (
    <>
      <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput}
            label="Phone number"
            name="phone"
            required={!props.disabled ? 'Phone number is required' : false}
            disabled={props.disabled}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput}
            label="Alternate phone number"
            name="alternatePhone"
            disabled={props.disabled}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field as={TextInput} label="Email" name="email" disabled />
        </Flex.Item>
        {!props.disabled || haveEmergencyContact(props.application) ? (
          <Flex basis="100%" pt={{ xs: 6, sm: 0 }}>
            <Heading variant="titleS" level={3}>
              🚨 Emergency Contact
            </Heading>
          </Flex>
        ) : null}
        {!props.disabled ? (
          <Flex.Item basis={{ xs: '100%' }}>
            <Field
              as={Checkbox}
              label="Would you like to provide an emergency contact?"
              name="provideEmergencyContact"
            />
          </Flex.Item>
        ) : null}
        {provideEmergencyContact ? (
          <>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Name"
                name="emergencyName"
                disabled={props.disabled}
                required={!props.disabled && provideEmergencyContact ? 'Name is required' : false}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              {/* //TODO: This field becomes a Select and use RelationshipCode from Application. Update with Aurora lastest changes */}
              <Field
                as={Select}
                label="Relationship"
                name="emergencyRelationship"
                disabled={props.disabled}
                appearance="styled"
                required={
                  !props.disabled && provideEmergencyContact ? 'Relationship is required' : false
                }
              >
                <Select.Option label="Parent" value="parent" />
                <Select.Option label="Guardian" value="guardian" />
                <Select.Option label="Friend" value="friend" />
                <Select.Option label="Spouse" value="spouse" />
                <Select.Option label="Relative" value="relative" />
                <Select.Option label="Other" value="other" />
              </Field>
            </Flex.Item>
          </>
        ) : null}
      </Flex>
      {provideEmergencyContact ? (
        <Flex pb={6} gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Field
              as={TextInput}
              label="Phone number"
              name="emergencyPhone"
              disabled={props.disabled}
              required={
                !props.disabled && provideEmergencyContact ? 'Phone number is required' : false
              }
            />
          </Flex.Item>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Field
              as={TextInput}
              label="Email"
              name="emergencyEmail"
              validate={(value: string) => {
                if (!!value && !emailValidate(value)) {
                  return 'This email is invalid.'
                }

                return true
              }}
              disabled={props.disabled}
              required={!props.disabled && provideEmergencyContact ? 'Email is required' : false}
            />
          </Flex.Item>
        </Flex>
      ) : null}
    </>
  )
}

function haveEmergencyContact(application: RawApplicationResponse['data'] | null) {
  if (!application?.attributes?.personalInformation?.emergencyContact) {
    return false
  }

  return !isEmpty(
    omitBy(application?.attributes?.personalInformation?.emergencyContact, v => !v?.length),
  )
}
