import { Button, Dialog, Flex, Heading, Text } from '@applyboard/crystal-ui'
import { Dispatch, SetStateAction, useState } from 'react'
import { useScreenResize } from '../../../hooks'
import { breakpoints } from '../../../utils'

export function SubmitApprovalDialog(props: {
  showSubmitApprovalDialog: boolean
  setShowSubmitApprovalDialog: Dispatch<SetStateAction<boolean>>
  onSubmitConfirmation: () => void
  isLoading: boolean
}) {
  const [showModal, setShowModal] = useState(window.innerWidth >= breakpoints.sm)

  useScreenResize(() => {
    setShowModal(window.innerWidth >= breakpoints.sm)
  })

  if (!props.showSubmitApprovalDialog) return null

  return (
    <>
      <Flex hideAbove="sm" direction="column" gap={10} grow={1}>
        <Heading level={1} variant="headlineL" align="center">
          Submit Application?
        </Heading>
        <Flex direction="column" gap={4} align="center">
          <Text>Are you sure you want to save and submit your application to the school?</Text>
        </Flex>
        <Flex pt={1} direction="column" gap={4}>
          <>
            <Button
              onClick={() => {
                props.onSubmitConfirmation()
              }}
              loading={props.isLoading}
              intent="primary"
              width="fill"
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                props.setShowSubmitApprovalDialog(false)
              }}
              emphasis="highlighted"
              width="fill"
            >
              Cancel
            </Button>
          </>
        </Flex>
      </Flex>

      <Dialog
        onOpenChange={open => {
          props.setShowSubmitApprovalDialog(open)
        }}
        open={props.showSubmitApprovalDialog && showModal}
      >
        <Dialog.Heading>Submit Application?</Dialog.Heading>
        <Dialog.Content>
          <Text>Are you sure you want to save and submit your application to the school?</Text>
        </Dialog.Content>
        <Dialog.Actions>
          <>
            <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
            <Button
              onClick={() => {
                props.onSubmitConfirmation()
              }}
              loading={props.isLoading}
              width="fill"
              intent="primary"
            >
              Submit
            </Button>
          </>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}
