import styled from '@emotion/styled'
import { Button, Flex, Text } from '@applyboard/crystal-ui'
import { down } from '../../../utils'
import { TimelineItemDot } from './TimelineItemDot'
import { RawApplicationResponse } from '../../../hooks'
import { isTabCompleted } from './utils'
import { useQueryClient } from '@tanstack/react-query'

type ApplicationTimelineProps = {
  selectedTab: number
  setSelectedTab: (tab: number) => void
  application: RawApplicationResponse['data'] | null
}

export function ApplicationTimeline(props: ApplicationTimelineProps) {
  const queryClient = useQueryClient()
  const timelineItems = [
    { key: crypto.randomUUID(), label: 'Personal Information' },
    { key: crypto.randomUUID(), label: 'Contact Information' },
    { key: crypto.randomUUID(), label: 'Status and Citizenship' },
    { key: crypto.randomUUID(), label: 'Education History' },
    { key: crypto.randomUUID(), label: 'Language Proficiency' },
    { key: crypto.randomUUID(), label: 'Other Required Documents' },
  ]

  return (
    <TimelineWrapper>
      <Flex hideBelow="md">
        <Timeline aria-label="Application Timeline">
          {timelineItems.map((timelineItem, index) => (
            <TimelineItem
              key={timelineItem.key}
              aria-label={`${timelineItem.label} ${
                isTabCompleted({ index: index + 1, application: props.application })
                  ? 'is complete'
                  : 'is incomplete'
              }`}
            >
              <TimelineItemDotWrapper>
                {index !== 0 && <TopLine />}
                <TimelineItemDot
                  selectedTab={props.selectedTab}
                  index={index}
                  application={props.application}
                />
                {index < timelineItems.length - 1 && <BottomLine />}
              </TimelineItemDotWrapper>

              {index + 1 === props.selectedTab ? (
                <Flex px={4} py={3}>
                  <Text variant="labelL">{timelineItem.label}</Text>
                </Flex>
              ) : (
                <Button
                  emphasis="transparent"
                  onClick={() => {
                    queryClient.invalidateQueries({
                      queryKey: ['applications', props.application?.id],
                    })
                    props.setSelectedTab(index + 1)
                  }}
                >
                  {timelineItem.label}
                </Button>
              )}
            </TimelineItem>
          ))}
        </Timeline>
      </Flex>
    </TimelineWrapper>
  )
}

const borderWidth = '1px'
const iconHeightPx = '20px'
const columnGapPx = '8px'
const rowGapPx = '24px'

const TimelineWrapper = styled.div({
  height: 'fit-content',
  [down('md')]: {
    display: 'none',
  },
})

const Timeline = styled.ol({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: columnGapPx,
  rowGap: rowGapPx,
  marginBlock: 0,
  paddingInline: 0,
})

const TimelineItem = styled.li({
  display: 'contents',
  textWrap: 'nowrap',
  'button > div': {
    justifyContent: 'flex-start',
  },
})

const TimelineItemDotWrapper = styled.div({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
})

const TopLine = styled.div(({ theme }) => {
  return {
    border: `${borderWidth} solid ${theme.colors.borderInactive}`,
    bottom: `calc(50% + (${iconHeightPx} / 2) + ${borderWidth})`,
    height: `calc(50% - (${iconHeightPx} / 2) + (${rowGapPx} / 2) - ${borderWidth})`,
    left: `calc(50% - ${borderWidth})`,
    position: 'absolute',
    width: 0,
  }
})

const BottomLine = styled.div(({ theme }) => {
  return {
    border: `1px solid ${theme.colors.borderInactive}`,
    height: `calc(50% - (${iconHeightPx} / 2) + (${rowGapPx} / 2) - ${borderWidth})`,
    left: `calc(50% - ${borderWidth})`,
    position: 'absolute',
    top: `calc(50% + (${iconHeightPx} / 2) + ${borderWidth})`,
    width: 0,
  }
})
