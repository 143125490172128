import { Card, Carousel, Flex, Heading, Text } from '@applyboard/crystal-ui'

export function MobileOnboarding() {
  const actionsContent = [
    {
      key: crypto.randomUUID(),
      heading: 'Complete your profile',
      text: 'Provide personal, status and citizenship information to quickly apply to programs.',
    },
    {
      key: crypto.randomUUID(),
      heading: 'Start an application',
      text: 'Find your program of interest and select your intake term to kick off your application.',
    },
    {
      key: crypto.randomUUID(),
      heading: 'Submit application',
      text: 'Complete your online application with the required information and documents.',
    },
  ]

  return (
    <Flex direction="column" gap={6}>
      <Card aria-label="Onboarding actions carousel" emphasis="elevated">
        <Carousel aria-label="Onboarding actions" itemsPerSlide={1}>
          {actionsContent.map((action, index) => (
            <Flex direction="column" gap={2} key={index}>
              <Heading level={3} variant="titleS">
                {index + 1}. {action.heading}
              </Heading>
              <Text variant="bodyL">{action.text}</Text>
            </Flex>
          ))}
        </Carousel>
      </Card>
      <Flex direction="column" gap={4} pt={2}>
        <Heading level={3} variant="headlineS">
          Submitted Applications
        </Heading>
        <Card aria-label="Applications" emphasis="elevated">
          <Flex direction="column" align="center" gap={2}>
            <img src="/application-clipboard-mobile.svg" alt="application clipboard" />
            <Heading level={4} variant="titleS">
              No applications to show
            </Heading>
            <Text variant="bodyL">Search for programs and apply</Text>
          </Flex>
        </Card>
      </Flex>
      <Flex direction="column" gap={4}>
        <Heading level={3} variant="headlineS">
          Notifications
        </Heading>
        <Card aria-label="Notifications" emphasis="elevated">
          <Flex direction="column" align="center" gap={2}>
            <img src="/megaphone-mobile.svg" alt="megaphone" />
            <Heading level={4} variant="titleS">
              No notifications
            </Heading>
            <Text variant="bodyL">Actionable items appear here</Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  )
}
