import { Link, Text } from '@applyboard/crystal-ui'

export function ReviewRequirementsButton() {
  return (
    <Text variant="labelL">
      <Link href="#" mode="override_user_choice_and_open_in_new_tab" variant="standalone">
        Review requirements
      </Link>
    </Text>
  )
}
