import { PropsWithChildren, useState } from 'react'
import { Header } from './Header'
import { Flex } from '@applyboard/crystal-ui'
import { Sidebar } from './Sidebar'
import styled from '@emotion/styled'
import { up } from '../../utils'

type NavBarsProps = {
  largePage?: boolean
  fullWidth?: boolean
  maxWidth?: number
}

export function NavBars(props: PropsWithChildren<NavBarsProps>) {
  const [isDesktopSidebarOpen, setDesktopSidebarOpen] = useState(false)
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false)

  return (
    <Flex direction="column">
      <Header
        desktopSidebarChange={() => {
          setMobileSidebarOpen(false)
          setDesktopSidebarOpen(!isDesktopSidebarOpen)
        }}
        mobileSidebarChange={() => {
          setDesktopSidebarOpen(false)
          setMobileSidebarOpen(!isMobileSidebarOpen)
        }}
      />
      <Flex>
        <Sidebar
          isDesktopSidebarOpen={isDesktopSidebarOpen}
          isMobileSidebarOpen={isMobileSidebarOpen}
          onMobileSidebarOpenChange={setMobileSidebarOpen}
        />
        <Main>
          <PageContainer
            largePage={props.largePage}
            fullWidth={props.fullWidth}
            maxWidth={props.maxWidth}
          >
            {props.children}
          </PageContainer>
        </Main>
      </Flex>
    </Flex>
  )
}

const Main = styled.main({
  paddingLeft: 0,
  paddingTop: 56,
  paddingBottom: 56,
  flexGrow: 1,
  maxWidth: '100%',
  [up('sm')]: {
    paddingLeft: 64,
    paddingTop: 96,
    paddingBottom: 80,
  },
})

const PageContainer = styled.div<{
  largePage?: boolean
  fullWidth?: boolean
  maxWidth?: number
}>(({ largePage, fullWidth, maxWidth = 984 }) => ({
  maxWidth: fullWidth ? '100%' : largePage ? 1366 : maxWidth,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: 24,
  paddingRight: 24,
  // This is a workdaround (Gambiarra): Upload component is hiding right border
  '[class*="FileUploadBox"]': {
    backgroundSize: 'calc(100% - 1px)',
    backgroundRepeat: 'no-repeat',
  },
}))
