import { Button, Card, Flex, Heading, Text } from '@applyboard/crystal-ui'
import { ArrowRightOutlineIcon } from '@applyboard/ui-icons'
import { PropsWithChildren } from 'react'

type ApplicationFormCardProps = {
  disabled?: boolean
  cardNumber: number
  title: string
  isLoading?: boolean
  onSubmitApplication?: () => void
}

export function ApplicationFormCard(props: PropsWithChildren<ApplicationFormCardProps>) {
  return (
    <Card>
      <Card.Header>
        <Flex justify="between" align="center">
          <Flex direction="column">
            <Text variant="bodyS">{props.cardNumber} of 6</Text>
            <Heading level={2} variant="titleM">
              {props.title}
            </Heading>
          </Flex>
          <Flex hideBelow="md" gap={4}>
            <FormSubmitButtonGroup
              cardNumber={props.cardNumber}
              disabled={props.disabled}
              isLoading={props.isLoading}
              onSubmitApplication={props.onSubmitApplication}
            />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Divider mt={2} mb={2} />
      <Flex direction="column" gap={4}>
        {props.children}
        <Flex hideAbove="md" direction="column" justify="end" gap={4}>
          <FormSubmitButtonGroup
            cardNumber={props.cardNumber}
            disabled={props.disabled}
            fullWidth
            isLoading={props.isLoading}
            onSubmitApplication={props.onSubmitApplication}
          />
        </Flex>
      </Flex>
    </Card>
  )
}

function FormSubmitButtonGroup(props: {
  cardNumber: number
  disabled?: boolean
  fullWidth?: boolean
  isLoading?: boolean
  onSubmitApplication?: () => void
}) {
  if (props.disabled) {
    if (props.cardNumber < 6) {
      return (
        <Button
          intent="primary"
          type="submit"
          width={props.fullWidth ? 'fill' : undefined}
          loading={props.isLoading}
        >
          Continue
        </Button>
      )
    }

    return null
  }

  if (props.onSubmitApplication) {
    return (
      <>
        <Button
          type="submit"
          intent="secondary"
          emphasis="outlined"
          loading={props.isLoading}
          width={props.fullWidth ? 'fill' : undefined}
        >
          Save
        </Button>
        <Button
          intent="primary"
          trailIcon={ArrowRightOutlineIcon}
          disabled={props.isLoading}
          onClick={props.onSubmitApplication}
          width={props.fullWidth ? 'fill' : undefined}
        >
          Save & Submit
        </Button>
      </>
    )
  }

  return (
    <Button
      type="submit"
      intent="primary"
      trailIcon={ArrowRightOutlineIcon}
      loading={props.isLoading}
      width={props.fullWidth ? 'fill' : undefined}
    >
      Save & Continue
    </Button>
  )
}
