import { useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useAuthManager } from './useAuthManager'
import { useAuthToken } from './useAuthToken'
import { GenericError } from '../../utils'

export function useAuthQuery<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  queryOptions?: Omit<
    UseQueryOptions<TQueryFnData, GenericError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<TData, Error> {
  const queryClient = useQueryClient()
  const { isActiveSession } = useAuthToken()
  const { isRefreshingAccessToken, refreshAccessToken, signout } = useAuthManager()

  const useQueryResponse = useQuery({
    queryKey,
    queryFn: async context => {
      try {
        if (!isActiveSession()) await refreshAccessToken({ throwOnError: true })
      } catch (e) {
        queryClient.invalidateQueries()
        signout()
        throw new Error('Unable to refresh access token')
      }

      return await queryFn(context)
    },
    enabled: !isRefreshingAccessToken,
    ...queryOptions,
  })

  return useQueryResponse
}
