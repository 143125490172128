import { useAuthQuery } from './auth'
import { request } from '../utils/request'
import { GetApplicationsResponse } from '@backend/students-api/routes/applications/GET/types'

export type RawListApplicationsResponse = GetApplicationsResponse

export enum ApplicationState {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  SCREENED = 'SCREENED',
  INCOMPLETE = 'INCOMPLETE',
  RESUBMITTED = 'RESUBMITTED',
  AWAITING_DECISION = 'AWAITING_DECISION',
  CLOSED_PRE_REGISTERED = 'CLOSED_PRE_REGISTERED',
  CLOSED_DUPLICATE = 'CLOSED_DUPLICATE',
  CLOSED_DOES_NOT_MEET_REQUIREMENTS = 'CLOSED_DOES_NOT_MEET_REQUIREMENTS',
  CLOSED_PROGRAM_CLOSED = 'CLOSED_PROGRAM_CLOSED',
  CLOSED_OTHER = 'CLOSED_OTHER',
  REJECTED = 'REJECTED',
  WAITLISTED = 'WAITLISTED',
  OFFER_SENT = 'OFFER_SENT',
  OFFER_DECLINED = 'OFFER_DECLINED',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  LOA_SENT = 'LOA_SENT',
  AWAITING_FINAL_REVIEW = 'AWAITING_FINAL_REVIEW',
  DEFERRAL_REQUESTED = 'DEFERRAL_REQUESTED',
  DEFERRAL_IN_PROGRESS = 'DEFERRAL_IN_PROGRESS',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS',
}

export function useListApplications() {
  const { isLoading, data } = useAuthQuery(
    ['app-list'],
    async () => {
      return await request<RawListApplicationsResponse>('/applications?page[size]=100', {
        method: 'GET',
        isExpectedResponse: (res): res is RawListApplicationsResponse => res,
      })
    },
    {
      enabled: true, // TODO: Problem with this config in useAuthQuery hook
    },
  )

  return {
    isLoadingApplications: isLoading,
    applications: data?.data || null,
  }
}
