import { Button, Combobox, createForm, Flex, Heading, Select, Text } from '@applyboard/crystal-ui'
import { ArrowRightOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { Dispatch, SetStateAction } from 'react'
import { RawApplicationResponse } from '../../../hooks/useGetApplication'
import { ProgramIntake, useGetProgramIntakes } from '../../../hooks/useGetProgramIntakes'
import { Program, useGetPrograms } from '../../../hooks/useGetPrograms'

type SelectProgramFormFields = {
  program: string
  intake: string
}

type SelectProgramProps = {
  application?: RawApplicationResponse['data'] | null
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  onSubmit: (data: { program?: string; intake?: string }) => void
  isLoading?: boolean
}

const { Form, Field, useFieldValues, useResetForm } = createForm<SelectProgramFormFields>()

export function SelectProgram(props: SelectProgramProps) {
  const { programs } = useGetPrograms()
  const { programIntakes } = useGetProgramIntakes()

  return (
    <Form
      defaultValues={{
        program:
          props.application?.attributes?.programsSelected?.[0]?.programInformation?.programId || '',
        intake:
          props.application?.attributes?.programsSelected?.[0]?.programInformation
            ?.intakeInformation?.intakeId || '',
      }}
      onSubmit={({ program, intake }) => {
        props.onSubmit({ program, intake })
      }}
    >
      <SelectProgramFields
        currentStep={props.currentStep}
        setCurrentStep={props.setCurrentStep}
        isLoading={props.isLoading}
        programs={programs}
        intakes={programIntakes}
      />
    </Form>
  )
}

type SelectProgramFieldsProps = Omit<SelectProgramProps, 'onSubmit'> & {
  programs: Array<Program>
  intakes: Array<ProgramIntake>
}

function SelectProgramFields(props: SelectProgramFieldsProps) {
  const { program } = useFieldValues(['program'])
  const resetForm = useResetForm()

  return (
    <Flex direction="column" gap={12}>
      <Flex justify="center">
        <Illustration src="/program-selection-illustration.svg" alt="Program Selection" />
      </Flex>
      <Flex gap={6} direction="column">
        <Heading level={1} align="center" variant="headlineL">
          Program Selection
        </Heading>
        <Text align={{ xs: 'left', sm: 'center' }} variant="bodyL">
          Let’s begin by finding a program and an intake you would like to apply to.
        </Text>
      </Flex>
      <Flex gap={6} direction="column">
        <Field
          as={Combobox}
          label="Program"
          required="Program is required"
          placeholder="Search"
          type="search"
          name="program"
          size="md"
          onChange={val => {
            if (val === '') {
              resetForm()
            }
          }}
        >
          {props.programs?.map(program => (
            <Combobox.Option
              key={program.programInformation.programId}
              label={
                program.programInformation.programTitle ||
                `Program ${program.programInformation.programId}`
              }
              value={program.programInformation.programId}
            />
          ))}
        </Field>
        {program ? (
          <Field
            as={Select}
            label="Intake"
            placeholder="Select"
            name="intake"
            required="Intake is required"
            appearance="styled"
          >
            {props.intakes?.map(intake => (
              <Select.Option key={intake.id} label={intake.startDate} value={intake.id} />
            ))}
          </Field>
        ) : null}
      </Flex>
      <Flex justify="end" pt={8} hideBelow="sm">
        <Button
          type="submit"
          intent="primary"
          trailIcon={ArrowRightOutlineIcon}
          loading={props.isLoading}
        >
          Save & Continue
        </Button>
      </Flex>
      <Flex justify="end" hideAbove="sm">
        <Button type="submit" intent="primary" loading={props.isLoading} width="fill">
          Save & Continue
        </Button>
      </Flex>
    </Flex>
  )
}

const Illustration = styled.img({
  maxWidth: 160,
  width: '100%',
})
