import { useMutation } from '@tanstack/react-query'
import { ConfirmUserParams } from 'schools-domain-backend-utils'
import { request } from '../utils/request'

export function useSignUpConfirmation() {
  const { isPending, mutate } = useMutation({
    mutationFn: async (params: ConfirmUserParams) => {
      return await request(`/users/${params.username}/confirm`, {
        method: 'POST',
        isExpectedResponse: (res): res is unknown => true,
        body: {
          temporaryPassword: params.temporaryPassword,
          newPassword: params.newPassword,
        },
      })
    },
  })

  return {
    isConfirmingSignUp: isPending,
    signUpConfirmation: mutate,
  }
}
