import { BaseRequest } from './BaseRequest'
import { getConfig } from '../../config'

export class AuthStudentRequest extends BaseRequest {
  constructor(
    endpoint: string,
    options: {
      body?: object
      headers?: Record<string, string>
      method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'
    } = {},
  ) {
    const url: string = `https://auth.students.${getConfig().hostedZoneName}${endpoint}`
    options.headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    }
    super(url, options)
  }
}
