import {
  Button,
  Combobox,
  createForm,
  DatePicker,
  FieldCompatibleProps,
  FileInfo,
  FileUpload,
  Flex,
  Select,
  TextInput,
} from '@applyboard/crystal-ui'
import { PlusOutlineIcon } from '@applyboard/ui-icons'
import { ApplicationResourceAttributes, FileData } from 'applications-types-lib'
import {
  COUNTRY_NAMES,
  CountryIsoCode,
} from 'schools-domain-backend-utils/dist/common-types/country'
import { RawApplicationResponse } from '../../../hooks/useGetApplication'
import { useUpdateApplication } from '../../../hooks/useUpdateApplication'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { ApplicationFormCard } from './ApplicationFormCard'
import {
  getFilesOfType,
  UploadFileData,
  useGetFileObjectFromApplicationFiles,
  useUploadFile,
} from '../../../hooks'
import { Loading } from '../../Loading'
import { ApplicationFileList } from './ApplicationFileList'
import { flatten } from 'lodash'
import { useApplicationFormContext } from './ApplicationForm'
import { useEffect } from 'react'

type SchoolType = NonNullable<ApplicationResourceAttributes['education']>[number]['schoolType']

type EducationHistoryFormFields = {
  educationHistory: Array<{
    schoolType: string
    country: string
    schoolName: string
    startDate: string
    endDate: string
    documents: Array<{
      id: string
      file: File
    }>
  }>
}

const { Form, Field, useFieldValues, useSetFieldValues } = createForm<EducationHistoryFormFields>()

type EducationHistoryTabProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
}

const defaultValue = {
  schoolType: '',
  country: '',
  schoolName: '',
  startDate: '',
  endDate: '',
  documents: [],
}

export function EducationHistoryTab(props: EducationHistoryTabProps) {
  // TODO: Uncomment when FileUpload component support download
  // const { applicationFiles, isLoadingApplicationFiles } = useGetFileObjectFromApplicationFiles({
  //   application: props.application,
  //   fileType: 'education_history',
  // })
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application?.id,
  })
  const { clearFiles, files, setFiles } = useApplicationFormContext()
  useEffect(() => {
    setFiles(
      getFilesOfType(['education_history'], props.application?.attributes?.files as FileData),
    )
  }, [])

  // TODO: Uncomment when FileUpload component support download
  // if (isLoadingApplicationFiles) {
  //   return <Loading />
  // }

  return (
    <Flex grow={1} direction="column">
      <Form
        defaultValues={{
          educationHistory: props.application?.attributes?.education?.map(education => ({
            schoolType: education?.schoolType || '',
            country: education?.country || '',
            schoolName: education?.schoolName || '',
            startDate: education?.startDate
              ? convertTimelessDateStrToLocalDate(education.startDate).toISOString()
              : '',
            endDate: education?.endDate
              ? convertTimelessDateStrToLocalDate(education.endDate).toISOString()
              : '',
            // TODO: Uncomment when FileUpload component support download
            // documents: applicationFiles,
            documents: [],
          })) || [
            {
              ...defaultValue,
              // TODO: Uncomment when FileUpload component support download
              // documents: applicationFiles,
            },
          ],
        }}
        onSubmit={data => {
          const educationHistory = data.educationHistory.filter((education, index) => {
            return isFieldRequired({
              index,
              disabled: false,
              education,
            })
          })

          if (props.disabled) {
            props.onSuccess()
          } else {
            updateApplication(
              {
                attributes: {
                  education: educationHistory.map(education => ({
                    schoolType: education.schoolType as SchoolType,
                    country: education.country as CountryIsoCode,
                    schoolName: education.schoolName,
                    startDate: education.startDate.substring(0, 10),
                    endDate: education.endDate.substring(0, 10),
                  })),
                  files,
                },
              },
              {
                onSuccess: response => {
                  clearFiles()
                  props.onSuccess(response)
                },
                onError: props.onError,
              },
            )
          }
        }}
      >
        <ApplicationFormCard
          cardNumber={4}
          title="📚  Education History"
          isLoading={isUpdatingApplication}
          disabled={props.disabled}
        >
          <EducationHistoryItems disabled={props.disabled} application={props.application} />
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}

type EducationHistoryItemsProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
}

function EducationHistoryItems(props: EducationHistoryItemsProps) {
  const { addFile, deleteFile } = useApplicationFormContext()
  const setFieldValues = useSetFieldValues()
  const { educationHistory } = useFieldValues(['educationHistory'])
  const { uploadFile } = useUploadFile({
    id: props.application?.id || '',
  })

  const documents = flatten(educationHistory.map(history => history.documents))

  const maxDate = new Date()
  const minDate = new Date()

  maxDate.setFullYear(maxDate.getFullYear() + 15)
  minDate.setFullYear(minDate.getFullYear() - 50)

  const educationUploadedFiles = Object.values(props.application?.attributes?.files || {}).some(
    file => file?.type === 'education_history',
  )

  return (
    <>
      <Flex direction="column" gap={12}>
        {educationHistory.map((education, index) => (
          // Not able to use crypto.randomUUID().
          // This was changing `key` value and user was losing input focus.
          <Flex direction="column" gap={4} key={`educationHistory.${index}`}>
            <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
              <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                <Field
                  as={
                    Select as React.FunctionComponent<
                      // Workaround to have an Array of objects as value
                      FieldCompatibleProps<unknown, HTMLElement> & {
                        appearance: string
                        children?: React.ReactNode
                        disabled?: boolean
                      }
                    >
                  }
                  label="Education level"
                  name={`educationHistory.${index}.schoolType`}
                  appearance="styled"
                  disabled={props.disabled}
                  required={
                    isFieldRequired({ index, education, disabled: props.disabled })
                      ? 'Education level is required'
                      : false
                  }
                >
                  <Select.Option value="SECONDARY" label="Secondary" />
                  <Select.Option value="COLLEGE" label="College" />
                  <Select.Option value="UNIVERSITY" label="University" />
                </Field>
              </Flex.Item>
              <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                <Field
                  as={
                    Combobox as React.FunctionComponent<
                      // Workaround to have an Array of objects as value
                      FieldCompatibleProps<unknown, HTMLElement> & {
                        size: string
                        children?: React.ReactNode
                        placeholder: string
                        disabled?: boolean
                      }
                    >
                  }
                  label="Country of Education"
                  name={`educationHistory.${index}.country`}
                  size="md"
                  placeholder="Select"
                  disabled={props.disabled}
                  required={
                    isFieldRequired({ index, education, disabled: props.disabled })
                      ? 'Country of Education is required'
                      : false
                  }
                >
                  {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                    <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
                  ))}
                </Field>
              </Flex.Item>
              <Flex.Item basis="100%">
                <Field
                  as={
                    TextInput as React.FunctionComponent<
                      FieldCompatibleProps<unknown, HTMLElement> & {
                        disabled?: boolean
                      }
                    >
                  }
                  label="School attended"
                  name={`educationHistory.${index}.schoolName`}
                  disabled={props.disabled}
                  required={
                    isFieldRequired({ index, education, disabled: props.disabled })
                      ? 'School attended is required'
                      : false
                  }
                />
              </Flex.Item>
              <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                <Field
                  as={
                    DatePicker as React.FunctionComponent<
                      // Workaround to have an Array of objects as value
                      FieldCompatibleProps<unknown, HTMLElement> & {
                        maxDate: string
                        minDate: string
                        disabled?: boolean
                      }
                    >
                  }
                  label="Start date"
                  name={`educationHistory.${index}.startDate`}
                  maxDate={maxDate.toISOString()}
                  minDate={minDate.toISOString()}
                  disabled={props.disabled}
                  required={
                    isFieldRequired({ index, education, disabled: props.disabled })
                      ? 'Start date is required'
                      : false
                  }
                  validate={date => {
                    const startDate = new Date(date)
                    const endDate = new Date(education.endDate)
                    if (endDate && startDate >= endDate) {
                      return 'Start date must be before end date'
                    }

                    return true
                  }}
                />
              </Flex.Item>
              <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                <Field
                  as={
                    DatePicker as React.FunctionComponent<
                      // Workaround to have an Array of objects as value
                      FieldCompatibleProps<unknown, HTMLElement> & {
                        maxDate: string
                        minDate: string
                        disabled?: boolean
                      }
                    >
                  }
                  label="End date (or expected)"
                  name={`educationHistory.${index}.endDate`}
                  maxDate={maxDate.toISOString()}
                  minDate={minDate.toISOString()}
                  disabled={props.disabled}
                  required={
                    isFieldRequired({ index, education, disabled: props.disabled })
                      ? 'End date is required'
                      : false
                  }
                  validate={date => {
                    const endDate = new Date(date)
                    const startDate = new Date(education.startDate)
                    if (startDate && startDate >= endDate) {
                      return 'End date must be after start date'
                    }

                    return true
                  }}
                />
              </Flex.Item>
              <Flex gap={4} direction="column" basis="100%">
                {!props.disabled ? (
                  <Field
                    as={
                      FileUpload as React.FunctionComponent<
                        // Workaround to have an Array of objects as value
                        FieldCompatibleProps<unknown, HTMLElement> & {
                          multiple: boolean
                          disabled?: boolean
                          uploadFile?: (
                            file: File,
                            params: { onSuccess: (fileId: string) => void; onError: () => void },
                          ) => void
                          onRemoveFile?: (file: FileInfo) => void
                        }
                      >
                    }
                    label={'Add your certificate, transcript, and/ or supporting document(s) below'}
                    name={`educationHistory.${index}.documents`}
                    multiple
                    required={
                      !educationUploadedFiles &&
                      isFieldRequired({ index, education, disabled: props.disabled })
                        ? 'Document upload is required'
                        : false
                    }
                    uploadFile={(
                      file: File,
                      {
                        onSuccess,
                        onError,
                      }: {
                        onSuccess: (fileId: string) => void
                        onError: (errorMessage?: string) => void
                      },
                    ) => {
                      uploadFile({
                        file: file,
                        fileType: 'education_history',
                        onSuccess: (fileId: string, uploadFileData: UploadFileData) => {
                          addFile(fileId, uploadFileData)
                          onSuccess(fileId)
                        },
                        onError,
                      })
                    }}
                    onRemoveFile={(fileInfo: FileInfo) => deleteFile(fileInfo.id)}
                  />
                ) : null}
                {props.application ? (
                  <ApplicationFileList
                    application={props.application}
                    disabled={props.disabled}
                    documents={documents}
                    fileType="education_history"
                  />
                ) : null}
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
      {!props.disabled ? (
        <Flex>
          <Button
            emphasis="highlighted"
            intent="primary"
            width="fill"
            leadIcon={PlusOutlineIcon}
            onClick={() => {
              setFieldValues({
                educationHistory: [...educationHistory, defaultValue],
              })
            }}
          >
            Add another education experience
          </Button>
        </Flex>
      ) : null}
    </>
  )
}

function isFieldRequired(params: {
  index: number
  education: EducationHistoryFormFields['educationHistory'][number]
  disabled?: boolean
}) {
  if (params.disabled) {
    return false
  }

  if (params.index === 0) {
    return true
  }
  const isEducationPartiallyFilled = Object.values(params.education).some(educationValue => {
    return (typeof educationValue === 'string' && educationValue.trim()) || educationValue.length
  })

  return isEducationPartiallyFilled
}
