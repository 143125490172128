import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuthManager } from '../../hooks'

export function ProtectedRoute({ isSignupLoginRoute }: { isSignupLoginRoute?: boolean }) {
  const location = useLocation()
  const { isLoggedIn } = useAuthManager()

  if (isLoggedIn()) {
    if (isSignupLoginRoute) return <Navigate to={'/'} />
    return <Outlet />
  }

  // prevent redirect loop
  if (isSignupLoginRoute) return <Outlet />
  return <Navigate to={'/login'} state={{ from: location.pathname }} />
}
