import { useQuery } from '@tanstack/react-query'
import { OAuth2TokenResponse } from 'schools-domain-backend-utils'
import { AuthStudentRequest, request } from '../../utils'
import { GenericError } from '../../utils'

interface RawRefreshResponse {
  access_token: string
}

interface IRefreshToken {
  getRefreshToken: () => string
  setCredentials: (obj: OAuth2TokenResponse) => void
}

export function useRefreshToken({ getRefreshToken, setCredentials }: IRefreshToken) {
  const { data, isLoading, refetch, error } = useQuery<string, GenericError>({
    queryKey: ['refreshAccessToken'],
    queryFn: async (): Promise<string> => {
      const response = await request<OAuth2TokenResponse>(
        new AuthStudentRequest('/oauth2/token', {
          method: 'POST',
          body: {
            grant_type: 'refresh_token',
            refresh_token: getRefreshToken(),
          },
        }),
        {
          isExpectedResponse: (res): res is RawRefreshResponse => res,
        },
      )
      setCredentials(response)
      return response.access_token
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
  })

  return {
    accessToken: data,
    isRefreshingAccessToken: isLoading,
    refreshAccessToken: refetch,
    refreshAccessTokenError: error,
  }
}
