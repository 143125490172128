import { Card, Flex, Heading, Tag, Text } from '@applyboard/crystal-ui'
import { RawApplicationResponse } from '../../../hooks'
import { ReviewRequirementsButton } from './ReviewRequirementsButton'

type DesktopHeaderProps = {
  application: RawApplicationResponse['data'] | null
}

export function DesktopHeader(props: DesktopHeaderProps) {
  const program = props.application?.attributes?.programsSelected?.find(
    program => program?.programInformation?.programTitle,
  )

  return (
    <Card>
      <Card.Header>
        <Flex justify="between">
          <Flex gap={4}>
            <Flex direction="column">
              <Heading level={2} variant="titleM">
                {program?.programInformation?.programTitle}
              </Heading>
              {program?.programInformation?.intakeInformation?.intakeTerm ? (
                <Text>{program.programInformation.intakeInformation.intakeTerm}</Text>
              ) : null}
            </Flex>
            {/* TODO: Remove while we dont have a friendly program id */}
            {/* <Tag intent="secondary" size="lg">
              {program?.programInformation?.programId}
            </Tag> */}
          </Flex>
          <ReviewRequirementsButton />
        </Flex>
      </Card.Header>
    </Card>
  )
}
