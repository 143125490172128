// `request` function extracted from ui-utils.
import axios, { AxiosResponse, AxiosError } from 'axios'
import { getConfig } from '../../config'
import { AuthStorage } from '../auth'
import { IRequest } from './IRequest'
import { NetworkError, HttpError, UnexpectedResponseError } from '../errors'

export type RequestParams<T> = {
  /**
   * HTTP verb to send.
   * @default 'GET'
   */
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'

  /**
   * The body object for non-get requests.
   * Object will be serialized with `JSON.stringify` unless a FormData or File is provided.
   * @default null
   */
  body?: FormData | File | object | null

  /**
   * The response that is used if a 404 is received.  The api sometimes responds
   * with a 404 if there is 0 records at a collection endpoint.  If not
   * provided, `request` will throw an error if 404 is received
   */
  defaultResponse?: T

  /**
   * A type-guard that should return true if the response is roughly the
   * expected shape.  Using this is a best practice because it will prevent
   * the rest of the app from trying to use an unexpected response.
   *
   * If the response is unexpected, `request` will throw an error that can be
   * handled like any other error
   */
  isExpectedResponse: (res: any) => res is T

  /**
   * Specifies what action to take when the API responds
   * with a redirect response. The default is to 'follow' which
   * will cause another API call to fired automatically.
   * The redirect option is documented here: https://developer.mozilla.org/en-US/docs/Web/API/fetch
   */
  handleRedirect?: 'follow' | 'error' | 'manual'

  /**
   * The endpoint type to hit.
   * @default 'api'
   */
  endpointType?: 'api' | 'auth'
}

const API_ROOT = `.students.${getConfig().hostedZoneName}`

export async function request<T = unknown>(
  endpoint: string | IRequest,
  params: RequestParams<T>,
): Promise<T> {
  let axiosObj = {}
  if (typeof endpoint === 'string') {
    const endpointType = params.endpointType || 'api'

    const requestHeaders: { 'Content-Type': string; Authorization?: string } = {
      'Content-Type': 'application/json',
    }

    const accessToken = AuthStorage.getAccessToken()
    if (!!accessToken && endpointType !== 'auth') {
      requestHeaders.Authorization = `Bearer ${accessToken}`
    }
    const fetchEndpoint = endpoint.startsWith('http')
      ? endpoint
      : `https://${endpointType}${API_ROOT}${endpoint}`

    axiosObj = {
      url: fetchEndpoint,
      method: params?.method || 'GET',
      headers: requestHeaders,
      data: params.body,
    }
  } else {
    let request = endpoint
    axiosObj = {
      url: request.url,
      method: request.method,
      headers: Object.fromEntries(request.headers),
      data: request.body,
    }
  }

  let res: AxiosResponse

  try {
    res = await axios(axiosObj)
  } catch (err) {
    if (err instanceof AxiosError && err.response?.status) {
      if (err.response.status === 404 && params.defaultResponse) {
        return params.defaultResponse
      }

      if (err.response?.data?.errors?.length) {
        if (err.response.data.errors[0]?.meta?.errorData?.errors?.[0]) {
          throw new HttpError(
            err.response.data.errors[0]?.meta?.errorData?.errors?.[0].status,
            err.response.data.errors[0]?.meta?.errorData?.errors?.[0].detail,
          )
        }
        throw new HttpError(err.response.status, err.response.data.errors[0].detail)
      }

      throw new HttpError(err.response?.status, err.response?.statusText || err.message)
    }

    throw new NetworkError()
  }

  if (!params.isExpectedResponse(res.data)) {
    throw new UnexpectedResponseError(res.status, 'Unexpected response')
  }

  return res.data
}
