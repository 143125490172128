import { request } from '../utils/request'
import { useQuery } from '@tanstack/react-query'

export type RawSchoolResponse = {
  data: {
    id: string
    type: 'school'
    attributes: {
      logo: string
      name: string
      url: string
    }
  }
}

export function useGetSchool() {
  const id = 'N2xUNRw-huxFsBNNQv9AG' // TODO: We dont know how to deal with School ID at the moment. So, it is hardcoded
  const { isLoading, data } = useQuery({
    queryKey: ['schools', id],
    queryFn: async () => {
      return await request<RawSchoolResponse>(`/schools/${id}`, {
        method: 'GET',
        isExpectedResponse: (res): res is RawSchoolResponse => res,
      })
    },
    enabled: true,
    refetchOnWindowFocus: false,
  })

  return {
    isLoadingSchool: isLoading,
    school: data?.data || null,
  }
}
