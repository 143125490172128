export type ProgramIntake = {
  id: string
  startDate: string
}

export function useGetProgramIntakes() {
  return {
    programIntakes: [
      {
        id: 'hRBIIrxp-E25ZLc9qKqyN',
        startDate: 'Spring 2024',
      },
      {
        id: 'cQaZ6f8Icf6dE5LFrWk8n',
        startDate: 'Fall 2024',
      },
      {
        id: '1ok4vRGT95VO6Hi3Za3o3',
        startDate: 'Winter 2024',
      },
    ],
  }
}
