import { RawListApplicationsResponse } from '../../hooks/useListApplications'
import { ApplicationsCard } from './ApplicationsCard'
import { ApplicationsTable } from './ApplicationsTable'

type ApplicationsListProps = {
  applications: RawListApplicationsResponse['data'] | null
}

export function ApplicationsList(props: ApplicationsListProps) {
  return (
    <>
      <ApplicationsCard applications={props.applications} />
      <ApplicationsTable applications={props.applications} />
    </>
  )
}
