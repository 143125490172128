import { Box, Flex, Heading } from '@applyboard/crystal-ui'
import { NavBars } from '../../components/NavBars'
import { DesktopOnboarding } from './DesktopOnboarding'
import { MobileOnboarding } from './MobileOnboarding'
import { useGetProfile, useLoggedInUser } from '../../hooks'

export function OnboardingPage() {
  return (
    <NavBars largePage>
      <Flex direction="column" gap={8}>
        <Box pt={8} pl={10}>
          <Hello />
        </Box>
        <Box hideBelow="sm">
          <DesktopOnboarding />
        </Box>
        <Box hideAbove="sm">
          <MobileOnboarding />
        </Box>
      </Flex>
    </NavBars>
  )
}

export function Hello() {
  const { username } = useLoggedInUser()
  const { isLoadingProfile, profile } = useGetProfile({ id: username })

  if (isLoadingProfile || !profile) {
    return (
      <Heading level={1} variant="headlineL">
        Hello there.
      </Heading>
    )
  }

  return (
    <Heading level={1} variant="headlineL">
      {`Hello, ${[profile.attributes.givenName, profile.attributes.familyName]
        .filter(v => v)
        .join(' ')}.`}
    </Heading>
  )
}
