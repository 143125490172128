import { useState } from 'react'
import { OnBoardingBox } from '../../components/OnBoardingBox'
import { SignUpForm } from './SignUpForm'
import { VerifySignedUpEmail } from './VerifySignedUpEmail'

export function SignUpPage() {
  const [userId, setUserId] = useState<string>('')

  return (
    <OnBoardingBox>
      {userId ? <VerifySignedUpEmail userId={userId} /> : <SignUpForm onSuccess={setUserId} />}
    </OnBoardingBox>
  )
}
