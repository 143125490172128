import { Card, Flex, Heading, Tag, Text } from '@applyboard/crystal-ui'
import { RawApplicationResponse } from '../../../hooks'
import { ReviewRequirementsButton } from './ReviewRequirementsButton'

type MobileHeaderProps = {
  application: RawApplicationResponse['data'] | null
}

export function MobileHeader(props: MobileHeaderProps) {
  const program = props.application?.attributes?.programsSelected?.find(
    program => program?.programInformation?.programTitle,
  )

  return (
    <Card>
      <Card.Header>
        <Flex justify="between">
          <Flex direction="column">
            <Heading level={2} variant="titleM">
              {program?.programInformation?.programTitle}
            </Heading>
            {program?.programInformation?.intakeInformation?.intakeTerm ? (
              <Text>{program.programInformation.intakeInformation.intakeTerm}</Text>
            ) : null}
          </Flex>
          {/* TODO: Remove while we dont have a friendly program id */}
          {/* <Tag intent="secondary" size="lg">
            {program?.programInformation?.programId}
          </Tag> */}
        </Flex>
      </Card.Header>
      <Card.Divider mt={2} mb={2} />
      <Flex justify="center">
        <ReviewRequirementsButton />
      </Flex>
    </Card>
  )
}
