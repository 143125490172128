import { useMutation, useQueryClient } from '@tanstack/react-query'
import { nanoid } from 'nanoid'
import { useUpdateApplication } from './useUpdateApplication'
import { BaseRequest, request } from '../utils'
import { FileData } from 'applications-types-lib'

interface IS3FileUpload {
  file: File
  url: string
  fields: Record<string, string>
}

export type UploadFileData = {
  contentType: string
  fileName: string
  hash: string
  type: string
}

export type FileType =
  | 'passport'
  | 'immigration'
  | 'language_proficiency'
  | 'education_history'
  | 'additional_documents'

export function getFilesOfType(fileTypes: FileType[], fileData?: FileData) {
  return Object.entries(fileData || {}).reduce((acc, [id, fileData]) => {
    if (!fileData) return acc
    if (fileTypes.includes(fileData.type as FileType)) {
      acc[id] = fileData
    }
    return acc
  }, {} as FileData)
}

function useS3Upload() {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: async (params: IS3FileUpload) => {
      const formData = new FormData()
      Object.keys(params.fields).forEach((key: string) => formData.append(key, params.fields[key]))
      formData.append('file', params.file)
      return await request(
        new BaseRequest(params.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: formData,
        }),
        {
          isExpectedResponse: (res): res is string => {
            return true // correct response is empty string
          },
        },
      )
    },
  })

  return {
    isLoadingS3Upload: isPending,
    s3UploadAsync: mutateAsync,
  }
}

export function useUploadFile(props: { id: string }) {
  const queryClient = useQueryClient()
  const { isUpdatingApplication, updateApplicationAsync } = useUpdateApplication(props)
  const { isLoadingS3Upload, s3UploadAsync } = useS3Upload()

  async function uploadFile(params: {
    file: File
    fileType: FileType
    onSuccess?: (fileId: string, uploadFileData: UploadFileData) => void
    onError?: (errorMessage?: string) => void
    preventInvalidateApplicationQuery?: boolean
  }) {
    const fileId = nanoid()
    const fileObject = {
      contentType: params.file.type,
      fileName: params.file.name,
      hash: nanoid(),
      type: params.fileType,
    }
    updateApplicationAsync({
      attributes: {
        files: {
          [fileId]: fileObject,
        },
      },
    })
      .then(response => {
        const uploadMeta = response.data.meta.files[fileId].upload
        return s3UploadAsync({
          file: params.file,
          url: uploadMeta.url,
          fields: uploadMeta.fields,
        })
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['applications', props.id] })
        if (params.onSuccess) params.onSuccess(fileId, fileObject)
      })
      .catch(error => {
        if (params.onError) params.onError()
      })
  }

  return {
    isUploadingFile: isUpdatingApplication || isLoadingS3Upload,
    uploadFile: uploadFile,
  }
}
