import {
  Button,
  createForm,
  DatePicker,
  FieldCompatibleProps,
  FileInfo,
  FileUpload,
  Flex,
  NumberInput,
  Select,
  TextInput,
} from '@applyboard/crystal-ui'
import { flatten, mergeWith, omitBy } from 'lodash'
import { PlusOutlineIcon, DeleteOutlineIcon } from '@applyboard/ui-icons'
import { RawApplicationResponse } from '../../../hooks/useGetApplication'
import { useUpdateApplication } from '../../../hooks/useUpdateApplication'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { ApplicationFileList } from './ApplicationFileList'
import { ApplicationFormCard } from './ApplicationFormCard'
import { ApplicationResourceAttributes, FileData } from 'applications-types-lib'
import {
  UploadFileData,
  useGetFileObjectFromApplicationFiles,
  getFilesOfType,
  useUploadFile,
} from '../../../hooks'
import { Loading } from '../../Loading'
import { useApplicationFormContext } from './ApplicationForm'
import { useEffect } from 'react'
type TestType = NonNullable<ApplicationResourceAttributes['englishProficiency']>[number]['testType']

type LanguageProficiencyFormFields = {
  languageProficiency: Array<{
    testType: string
    referenceNumber: string
    documents: Array<{
      id: string
      file: File
    }>
    dateOfCompletion: string
    overallScore: string
    subscores: {
      listening: string
      reading: string
      writing: string
      speaking: string
    }
  }>
}

const { Form, Field, useFieldValues, useSetFieldValues } =
  createForm<LanguageProficiencyFormFields>()

type LanguageProficiencyTabProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
}

const defaultValue = {
  testType: '',
  referenceNumber: '',
  documents: [],
  dateOfCompletion: '',
  overallScore: '',
  subscores: {
    listening: '',
    reading: '',
    writing: '',
    speaking: '',
  },
}

export function LanguageProficiencyTab(props: LanguageProficiencyTabProps) {
  // TODO: Uncomment when FileUpload component support download
  // const { applicationFiles, isLoadingApplicationFiles } = useGetFileObjectFromApplicationFiles({
  //   application: props.application,
  //   fileType: 'language_proficiency',
  // })
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application?.id,
  })
  const { clearFiles, files, setFiles } = useApplicationFormContext()
  useEffect(() => {
    setFiles(
      getFilesOfType(['language_proficiency'], props.application?.attributes?.files as FileData),
    )
  }, [])

  // TODO: Uncomment when FileUpload component support download
  // if (isLoadingApplicationFiles) {
  //   return <Loading />
  // }

  return (
    <Flex grow={1} direction="column">
      <Form
        defaultValues={{
          languageProficiency: props.application?.attributes?.englishProficiency?.length
            ? props.application?.attributes?.englishProficiency?.map(english => ({
                testType: english?.testType || '',
                referenceNumber: english?.referenceNumber || '',
                // TODO: Uncomment when FileUpload component support download
                // documents: applicationFiles,
                documents: [],
                dateOfCompletion: english?.dateOfCompletion
                  ? convertTimelessDateStrToLocalDate(english.dateOfCompletion).toISOString()
                  : '',
                overallScore: english?.overallScore || '',
                subscores: {
                  listening: english?.subscores?.listening?.toString() || '',
                  reading: english?.subscores?.reading?.toString() || '',
                  writing: english?.subscores?.writing?.toString() || '',
                  speaking: english?.subscores?.speaking?.toString() || '',
                },
              }))
            : [
                {
                  ...defaultValue,
                  // TODO: Uncomment when FileUpload component support download
                  // documents: applicationFiles,
                  testType:
                    props.application?.attributes?.englishProficiency &&
                    props.application?.attributes?.englishProficiency.length === 0
                      ? 'i-dont-have-this'
                      : '',
                },
              ],
        }}
        onSubmit={data => {
          if (props.disabled) {
            props.onSuccess()
          } else {
            updateApplication(
              {
                attributes: {
                  englishProficiency: data.languageProficiency
                    .filter(english => getFieldsInfo(english.testType))
                    .map(english => ({
                      ...omitBy(
                        {
                          testType: english.testType as TestType,
                          referenceNumber: english.referenceNumber,
                          dateOfCompletion: english.dateOfCompletion.substring(0, 10),
                          overallScore: english.overallScore,
                        },
                        v => !v?.length,
                      ),
                      subscores: mergeWith(
                        {},
                        omitBy(english.subscores, v => !v?.length),
                        (_, src) => parseFloat(src),
                      ),
                    })),
                  files,
                },
              },
              {
                onSuccess: response => {
                  clearFiles()
                  props.onSuccess(response)
                },
                onError: props.onError,
              },
            )
          }
        }}
      >
        <ApplicationFormCard
          cardNumber={4}
          title="🗣️  Language Proficiency"
          isLoading={isUpdatingApplication}
          disabled={props.disabled}
        >
          <LanguageProficiencyItems disabled={props.disabled} application={props.application} />
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}

type LanguageProficiencyItemsProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
}

function LanguageProficiencyItems(props: LanguageProficiencyItemsProps) {
  const setFieldValues = useSetFieldValues()
  const { languageProficiency } = useFieldValues(['languageProficiency'])
  const hasNoLanguageTest = languageProficiency.some(
    language => language.testType === 'i-dont-have-this',
  )

  const documents = flatten(languageProficiency.map(language => language.documents))

  return (
    <>
      <Flex direction="column" gap={12}>
        {languageProficiency.map((language, index) => (
          // Not able to use crypto.randomUUID().
          // This was changing `key` value and user was losing input focus.
          <Flex direction="column" gap={4} key={`languageProficiency.${index}`}>
            <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
              <Flex.Item basis="100%">
                <Field
                  as={
                    Select as React.FunctionComponent<
                      // Workaround to have an Array of objects as value
                      FieldCompatibleProps<unknown, HTMLElement> & {
                        appearance: string
                        children?: React.ReactNode
                        disabled?: boolean
                      }
                    >
                  }
                  label="Test type"
                  name={`languageProficiency.${index}.testType`}
                  appearance="styled"
                  disabled={props.disabled}
                  required={
                    isFieldRequired({ index, language, disabled: props.disabled })
                      ? 'Test type is required'
                      : false
                  }
                >
                  <Select.Option value="IELTS" label="IELTS" />
                  <Select.Option value="PTE" label="PTE Academic" />
                  <Select.Option value="TOEFL" label="TOEFL®️" />
                  {languageProficiency.length === 1 ? (
                    <Select.Option value="i-dont-have-this" label="I don’t have this" />
                  ) : null}
                </Field>
              </Flex.Item>
              {language.testType !== '' && language.testType !== 'i-dont-have-this' ? (
                <SelectedTypeFields
                  testType={language.testType}
                  index={index}
                  disabled={props.disabled}
                  application={props.application}
                  language={language}
                  documents={documents}
                />
              ) : null}
            </Flex>
            {!props.disabled && languageProficiency.length > 1 ? (
              <Flex>
                <Button
                  emphasis="highlighted"
                  intent="negative"
                  width="fill"
                  leadIcon={DeleteOutlineIcon}
                  onClick={() =>
                    setFieldValues({
                      languageProficiency: languageProficiency.filter((_, idx) => idx !== index),
                    })
                  }
                >
                  Remove language test
                </Button>
              </Flex>
            ) : null}
          </Flex>
        ))}
      </Flex>
      {!props.disabled ? (
        <Flex>
          <Button
            emphasis="highlighted"
            intent="primary"
            width="fill"
            leadIcon={PlusOutlineIcon}
            disabled={hasNoLanguageTest}
            onClick={() => {
              setFieldValues({
                languageProficiency: [...languageProficiency, defaultValue],
              })
            }}
          >
            Add another language test
          </Button>
        </Flex>
      ) : null}
    </>
  )
}

type SelectedTypeFieldsProps = {
  testType: string
  index: number
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
  language: LanguageProficiencyFormFields['languageProficiency'][number]
  documents: Array<{
    id: string
    file: File
  }>
}

function SelectedTypeFields(props: SelectedTypeFieldsProps) {
  const { uploadFile } = useUploadFile({
    id: props.application?.id || '',
  })
  const { addFile, deleteFile } = useApplicationFormContext()

  const fieldsInfo = getFieldsInfo(props.testType)
  if (!fieldsInfo) return null

  const maxDate = new Date()
  const minDate = new Date()

  maxDate.setFullYear(maxDate.getFullYear() + 15)
  minDate.setFullYear(minDate.getFullYear() - 50)

  const languageUploadedFiles = Object.values(props.application?.attributes?.files || {}).some(
    file => file?.type === 'language_proficiency',
  )

  return (
    <>
      <Flex basis="100%" gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={
              DatePicker as React.FunctionComponent<
                // Workaround to have an Array of objects as value
                FieldCompatibleProps<unknown, HTMLElement> & {
                  maxDate: string
                  minDate: string
                  disabled?: boolean
                }
              >
            }
            label="Test date"
            name={`languageProficiency.${props.index}.dateOfCompletion`}
            maxDate={maxDate.toISOString()}
            minDate={minDate.toISOString()}
            disabled={props.disabled}
            required={
              isFieldRequired({
                index: props.index,
                language: props.language,
                disabled: props.disabled,
              })
                ? 'Test date is required'
                : false
            }
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={
              TextInput as React.FunctionComponent<
                FieldCompatibleProps<unknown, HTMLElement> & {
                  disabled?: boolean
                }
              >
            }
            label={fieldsInfo.codeLabel}
            name={`languageProficiency.${props.index}.referenceNumber`}
            disabled={props.disabled}
            required={
              isFieldRequired({
                index: props.index,
                language: props.language,
                disabled: props.disabled,
              })
                ? `${fieldsInfo.codeLabel} is required`
                : false
            }
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={
              NumberInput as React.FunctionComponent<
                FieldCompatibleProps<unknown, HTMLElement> & {
                  min: number
                  max: number
                  disabled?: boolean
                }
              >
            }
            label="Overall score"
            name={`languageProficiency.${props.index}.overallScore`}
            max={fieldsInfo.maxOverall}
            min={fieldsInfo.minOverall}
            required={
              isFieldRequired({
                index: props.index,
                language: props.language,
                disabled: props.disabled,
              })
                ? 'Overall score is required'
                : false
            }
            validate={data => {
              if (
                parseInt(data) < fieldsInfo.minOverall ||
                parseInt(data) > fieldsInfo.maxOverall
              ) {
                return 'Invalid score.'
              }

              return true
            }}
            disabled={props.disabled}
          />
        </Flex.Item>
      </Flex>
      <Flex basis="100%" gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(25% - 12px)' }}>
          <Field
            as={
              NumberInput as React.FunctionComponent<
                FieldCompatibleProps<unknown, HTMLElement> & {
                  step: number
                  min: number
                  max: number
                  disabled?: boolean
                }
              >
            }
            label="Listening"
            name={`languageProficiency.${props.index}.subscores.listening`}
            max={fieldsInfo.maxSubscore}
            min={fieldsInfo.minSubscore}
            step={fieldsInfo.step}
            required={
              isFieldRequired({
                index: props.index,
                language: props.language,
                disabled: props.disabled,
              })
                ? 'Listening score is required'
                : false
            }
            validate={data => {
              return validateSubscore(props.testType, parseInt(data))
            }}
            disabled={props.disabled}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(25% - 12px)' }}>
          <Field
            as={
              NumberInput as React.FunctionComponent<
                FieldCompatibleProps<unknown, HTMLElement> & {
                  step: number
                  min: number
                  max: number
                  disabled?: boolean
                }
              >
            }
            label="Reading"
            name={`languageProficiency.${props.index}.subscores.reading`}
            max={fieldsInfo.maxSubscore}
            min={fieldsInfo.minSubscore}
            step={fieldsInfo.step}
            required={
              isFieldRequired({
                index: props.index,
                language: props.language,
                disabled: props.disabled,
              })
                ? 'Reading score is required'
                : false
            }
            validate={data => {
              return validateSubscore(props.testType, parseInt(data))
            }}
            disabled={props.disabled}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(25% - 12px)' }}>
          <Field
            as={
              NumberInput as React.FunctionComponent<
                FieldCompatibleProps<unknown, HTMLElement> & {
                  step: number
                  min: number
                  max: number
                  disabled?: boolean
                }
              >
            }
            label="Writing"
            name={`languageProficiency.${props.index}.subscores.writing`}
            max={fieldsInfo.maxSubscore}
            min={fieldsInfo.minSubscore}
            step={fieldsInfo.step}
            required={
              isFieldRequired({
                index: props.index,
                language: props.language,
                disabled: props.disabled,
              })
                ? 'Writing score is required'
                : false
            }
            validate={data => {
              return validateSubscore(props.testType, parseInt(data))
            }}
            disabled={props.disabled}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(25% - 12px)' }}>
          <Field
            as={
              NumberInput as React.FunctionComponent<
                FieldCompatibleProps<unknown, HTMLElement> & {
                  step: number
                  min: number
                  max: number
                  disabled?: boolean
                }
              >
            }
            label="Speaking"
            name={`languageProficiency.${props.index}.subscores.speaking`}
            max={fieldsInfo.maxSubscore}
            min={fieldsInfo.minSubscore}
            step={fieldsInfo.step}
            required={
              isFieldRequired({
                index: props.index,
                language: props.language,
                disabled: props.disabled,
              })
                ? 'Speaking score is required'
                : false
            }
            validate={data => {
              return validateSubscore(props.testType, parseInt(data))
            }}
            disabled={props.disabled}
          />
        </Flex.Item>
      </Flex>
      <Flex basis="100%" direction="column" gap={4}>
        {!props.disabled ? (
          <Field
            // Workaround to have an Array of objects as value
            as={
              FileUpload as React.FunctionComponent<
                FieldCompatibleProps<unknown, HTMLElement> & {
                  disabled?: boolean
                  uploadFile?: (
                    file: File,
                    params: { onSuccess: (fileId: string) => void; onError: () => void },
                  ) => void
                  onRemoveFile?: (file: FileInfo) => void
                }
              >
            }
            label="Add your language proficiency document(s) below"
            name={`languageProficiency.${props.index}.documents`}
            disabled={props.disabled}
            required={
              !languageUploadedFiles &&
              isFieldRequired({
                index: props.index,
                language: props.language,
                disabled: props.disabled,
              })
                ? 'Document is required'
                : false
            }
            uploadFile={(
              file: File,
              {
                onSuccess,
                onError,
              }: {
                onSuccess: (fileId: string) => void
                onError: (errorMessage?: string) => void
              },
            ) => {
              uploadFile({
                file: file,
                fileType: 'language_proficiency',
                onSuccess: (fileId: string, uploadFileData: UploadFileData) => {
                  addFile(fileId, uploadFileData)
                  onSuccess(fileId)
                },
                onError,
              })
            }}
            onRemoveFile={(fileInfo: FileInfo) => deleteFile(fileInfo.id)}
          />
        ) : null}
        {props.application ? (
          <ApplicationFileList
            application={props.application}
            disabled={props.disabled}
            documents={props.documents}
            fileType="language_proficiency"
          />
        ) : null}
      </Flex>
    </>
  )
}

function getFieldsInfo(testType: string) {
  switch (testType) {
    case 'IELTS':
      return {
        minOverall: 0,
        maxOverall: 9,
        minSubscore: 0,
        maxSubscore: 9,
        step: 0.5,
        codeLabel: 'TRF number',
      }
    case 'TOEFL':
      return {
        minOverall: 0,
        maxOverall: 120,
        minSubscore: 0,
        maxSubscore: 30,
        step: 1,
        codeLabel: 'Appointment number',
      }
    case 'PTE':
      return {
        minOverall: 10,
        maxOverall: 90,
        minSubscore: 10,
        maxSubscore: 90,
        step: 1,
        codeLabel: 'Score report code',
      }
  }

  return null
}

function validateSubscore(testType: string, score: number): true | string {
  const fieldsInfo = getFieldsInfo(testType)
  if (!fieldsInfo) return true

  const { minSubscore, maxSubscore } = fieldsInfo

  if (score < minSubscore || score > maxSubscore) {
    return 'Invalid score.'
  }

  return true
}

function isFieldRequired(params: {
  index: number
  language: LanguageProficiencyFormFields['languageProficiency'][number]
  disabled?: boolean
}) {
  if (params.disabled) {
    return false
  }

  if (params.index === 0) {
    return true
  }

  return !!params.language.testType
}
