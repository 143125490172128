import { useAuthMutation } from './auth'
import { request } from '../utils/request'
import { ApplicationResource, ApplicationResourceAttributes } from 'applications-types-lib'
import { ApplicationState } from './useListApplications'
import {
  IntakeInformation,
  ProgramInformation,
} from 'applications-types-lib/dist/resources/applications/programs/types'

type CreateApplicationParams = {
  programId: ProgramInformation['programId']
  intakeId?: Exclude<IntakeInformation, undefined>['intakeId']
}

type RawApplicationsResponse = {
  data: ApplicationResource
}

export function useCreateApplication() {
  const { isPending, mutate, data } = useAuthMutation(async (params: CreateApplicationParams) => {
    const programData: ApplicationResourceAttributes['programsSelected'][number] = {
      priority: 0,
      programInformation: {
        programId: params.programId,
      },
    }
    if (params.intakeId) {
      programData.programInformation.intakeInformation = {
        intakeId: params.intakeId,
      } as IntakeInformation // Necessary hack because the library expects intakeTerm
    }

    return await request<RawApplicationsResponse>('/applications', {
      method: 'POST',
      isExpectedResponse: (res): res is RawApplicationsResponse => res,
      body: {
        data: {
          type: 'application',
          attributes: {
            programsSelected: [programData],
            applicationState: ApplicationState.DRAFT,
          },
        },
      },
    })
  })

  return {
    isCreatingApplication: isPending,
    createApplication: mutate,
    application: data?.data || null,
  }
}
