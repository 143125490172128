import { useMutation } from '@tanstack/react-query'
import { request } from '../utils/request'
import {
  NewProfileResource,
  VersionedProfileResource,
} from '@backend/students-api/resources/profile'

type SignUpParams = NewProfileResource['attributes']

type RawProfilesResponse = {
  data: VersionedProfileResource
}

export function useSignUp() {
  const { isPending, mutate } = useMutation({
    mutationFn: async (params: SignUpParams) => {
      return await request<RawProfilesResponse>('/profiles', {
        method: 'POST',
        isExpectedResponse: (res): res is RawProfilesResponse => res,
        body: {
          data: {
            type: 'student_profile',
            attributes: {
              givenName: params.givenName,
              familyName: params.familyName,
              email: params.email,
            },
          },
        },
      })
    },
  })

  return {
    isSigningUp: isPending,
    signUp: mutate,
  }
}
