import { useAuthMutation } from './auth'
import { ApiStudentRequest, request } from '../utils'
import { VersionedApplicationResourceWithFiles } from 'applications-types-lib'
import { UpdateApplicationRequest } from '@backend/students-api/routes/applications/{id}/PATCH/types'

type UpdateApplicationProps = {
  id?: string
}

type UpdateApplicationParams = {
  attributes?: UpdateApplicationRequest['data']['attributes']
}

type RawApplicationsResponse = {
  data: VersionedApplicationResourceWithFiles
}

export function useUpdateApplication(props: UpdateApplicationProps) {
  const { isPending, mutate, mutateAsync } = useAuthMutation(
    async (params: UpdateApplicationParams) => {
      const attributes: UpdateApplicationRequest['data']['attributes'] = {
        files: {},
        ...params.attributes,
      }

      return await request<RawApplicationsResponse>(
        new ApiStudentRequest(`/applications/${props.id}`, {
          method: 'PATCH',
          body: {
            data: {
              id: props.id,
              type: 'application',
              attributes,
            },
          },
        }),
        {
          isExpectedResponse: (res): res is RawApplicationsResponse => res,
        },
      )
    },
  )

  return {
    isUpdatingApplication: isPending,
    updateApplication: mutate,
    updateApplicationAsync: mutateAsync,
  }
}
