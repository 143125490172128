import { Flex } from '@applyboard/crystal-ui'
import { RawApplicationResponse } from '../../../hooks'
import { DesktopHeader } from './DesktopHeader'
import { MobileHeader } from './MobileHeader'

type ApplicationHeaderProps = {
  application: RawApplicationResponse['data'] | null
}

export function ApplicationHeader(props: ApplicationHeaderProps) {
  return (
    <>
      <Flex hideAbove="md" direction="column">
        <MobileHeader application={props.application} />
      </Flex>
      <Flex hideBelow="md" direction="column">
        <DesktopHeader application={props.application} />
      </Flex>
    </>
  )
}
