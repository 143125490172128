// extracted from crystal-ui (this is an internal function used in the package)
export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

export type Breakpoint = keyof typeof breakpoints

export type Responsive<T> = Partial<Record<Breakpoint, T>>

export function up(b: Breakpoint) {
  return `@media (min-width: ${breakpoints[b]}px)`
}

export function down(b: Breakpoint) {
  return `@media (max-width: ${breakpoints[b] - 1}px)`
}
