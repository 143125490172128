import { FileData } from 'applications-types-lib'
import { createContext, useCallback, useContext, useState } from 'react'
import type { UploadFileData } from '../../../hooks'

interface IApplicationFormContext {
  addFile: (fileId: string, fileData: UploadFileData) => void
  clearFiles: () => void
  deleteFile: (fileId: string) => void
  files: FileData
  setFiles: (fileData?: FileData) => void
}

const ApplicationFormContext = createContext<IApplicationFormContext | undefined>(undefined)

export function useApplicationFormContext(): IApplicationFormContext {
  return useContext(ApplicationFormContext) as IApplicationFormContext
}

export function ApplicationForm({
  children,
  fileData,
}: {
  children: React.ReactNode
  fileData?: FileData
}) {
  const [formFiles, setFormFiles] = useState<FileData>(fileData || {})

  function addFile(fileId: string, uploadFileData: UploadFileData) {
    if (!!formFiles[fileId]) return
    setFormFiles({
      ...formFiles,
      [fileId]: uploadFileData,
    })
  }

  function deleteFile(fileId: string) {
    if (!formFiles[fileId]) return
    setFormFiles({
      ...formFiles,
      [fileId]: null,
    })
  }

  const setFiles = useCallback((fileData?: FileData) => {
    if (!fileData) {
      setFormFiles({})
      return
    }
    setFormFiles(fileData)
  }, [])

  function clearFiles() {
    setFiles()
  }

  return (
    <ApplicationFormContext.Provider
      value={{ files: formFiles, addFile, deleteFile, setFiles, clearFiles }}
    >
      {children}
    </ApplicationFormContext.Provider>
  )
}
