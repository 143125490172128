import {
  Checkbox,
  CheckboxGroup,
  Combobox,
  createForm,
  DatePicker,
  FieldCompatibleProps,
  Flex,
  Heading,
  Select,
  TextInput,
} from '@applyboard/crystal-ui'
import {
  COUNTRY_NAMES,
  CountryIsoCode,
} from 'schools-domain-backend-utils/dist/common-types/country'
import { GENDER_NAMES, GenderCode } from 'schools-domain-backend-utils/dist/common-types/gender'
import { RawApplicationResponse } from '../../../hooks/useGetApplication'
import { useUpdateApplication } from '../../../hooks/useUpdateApplication'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { ApplicationFormCard } from './ApplicationFormCard'

type PersonalInformationFormFields = {
  givenName: string
  middleName: string
  familyName: string
  legalNameConfirmation: string[]
  dateOfBirth: string
  nationality: string
  gender: string
  country: string
  streetAddress: string
  unitNumber: string
  city: string
  province: string
  postalCode: string
}

const { Form, Field } = createForm<PersonalInformationFormFields>()

type PersonalInformationTabProps = {
  disabled?: boolean
  application: RawApplicationResponse['data'] | null
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
}

export function PersonalInformationTab(props: PersonalInformationTabProps) {
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application?.id,
  })
  const maxDate = new Date()
  const minDate = new Date(1900, 0, 1)

  return (
    <Flex grow={1} direction="column">
      <Form
        defaultValues={{
          givenName:
            props.application?.attributes?.personalInformation?.basicPersonalInformation
              ?.givenName || '',
          middleName:
            props.application?.attributes?.personalInformation?.basicPersonalInformation
              ?.middleName || '',
          familyName:
            props.application?.attributes?.personalInformation?.basicPersonalInformation
              ?.familyName || '',
          legalNameConfirmation: props.application?.attributes?.personalInformation
            ?.basicPersonalInformation?.legalNameConfirmation
            ? ['confirm']
            : [],
          dateOfBirth: props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.dateOfBirth
            ? convertTimelessDateStrToLocalDate(
                props.application.attributes.personalInformation?.basicPersonalInformation
                  ?.dateOfBirth,
              ).toISOString()
            : '',
          nationality:
            props.application?.attributes?.personalInformation?.basicPersonalInformation
              ?.nationality || '',
          gender:
            props.application?.attributes?.personalInformation?.basicPersonalInformation?.gender ||
            '',
          country:
            props.application?.attributes?.personalInformation?.addresses?.currentAddress
              ?.country || '',
          streetAddress:
            props.application?.attributes?.personalInformation?.addresses?.currentAddress
              ?.streetAddress || '',
          unitNumber:
            props.application?.attributes?.personalInformation?.addresses?.currentAddress
              ?.unitNumber || '',
          city:
            props.application?.attributes?.personalInformation?.addresses?.currentAddress?.city ||
            '',
          province:
            props.application?.attributes?.personalInformation?.addresses?.currentAddress
              ?.province || '',
          postalCode:
            props.application?.attributes?.personalInformation?.addresses?.currentAddress
              ?.postalCode || '',
        }}
        onSubmit={data => {
          if (props.disabled) {
            props.onSuccess()
          } else {
            updateApplication(
              {
                attributes: {
                  personalInformation: {
                    basicPersonalInformation: {
                      givenName: data?.givenName,
                      middleName: data?.middleName,
                      familyName: data?.familyName,
                      legalNameConfirmation: data?.legalNameConfirmation?.[0] === 'confirm',
                      dateOfBirth: data?.dateOfBirth?.substring(0, 10),
                      nationality: (data?.nationality as CountryIsoCode) || null,
                      gender: (data?.gender as GenderCode) || null,
                    },
                    addresses: {
                      currentAddress: {
                        country: data.country,
                        streetAddress: data.streetAddress,
                        unitNumber: data.unitNumber,
                        city: data.city,
                        province: data.province,
                        postalCode: data.postalCode,
                      },
                      mailingAddress: {
                        country: data.country,
                        streetAddress: data.streetAddress,
                        unitNumber: data.unitNumber,
                        city: data.city,
                        province: data.province,
                        postalCode: data.postalCode,
                      },
                    },
                  },
                },
              },
              {
                onSuccess: props.onSuccess,
                onError: props.onError,
              },
            )
          }
        }}
      >
        <ApplicationFormCard
          cardNumber={1}
          title="👤  Personal Information"
          isLoading={isUpdatingApplication}
          disabled={props.disabled}
        >
          <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Given name"
                name="givenName"
                disabled={props.disabled}
                required={!props.disabled ? 'Given name is required' : false}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Middle name"
                name="middleName"
                disabled={props.disabled}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Family name"
                name="familyName"
                disabled={props.disabled}
              />
            </Flex.Item>
            {!props.disabled ? (
              <Flex.Item basis={{ xs: '100%' }}>
                <Field
                  as={
                    CheckboxGroup as React.FunctionComponent<
                      FieldCompatibleProps<string[], HTMLElement> & {
                        children?: React.ReactNode
                      }
                    >
                  }
                  name="legalNameConfirmation"
                  required={!props.disabled ? 'You have to confirm your valid name' : false}
                >
                  <Flex>
                    <CheckboxGroup.Option
                      label="I confirm that I have provided my legal name as displayed on my passport"
                      value="confirm"
                    />
                  </Flex>
                </Field>
              </Flex.Item>
            ) : null}
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={DatePicker}
                label="Date of birth"
                name="dateOfBirth"
                maxDate={maxDate.toISOString()}
                minDate={minDate.toISOString()}
                disabled={props.disabled}
                required={!props.disabled ? 'Date of birth is required' : false}
                validate={date => {
                  const selectedDate = new Date(date)
                  if (selectedDate > maxDate) {
                    return 'Select a valid date'
                  }

                  return true
                }}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={Combobox}
                label="Nationality"
                name="nationality"
                size="md"
                placeholder="Select"
                disabled={props.disabled}
                required={!props.disabled ? 'Nationality is required' : false}
              >
                {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                  <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
                ))}
              </Field>
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={Select}
                label="Gender"
                name="gender"
                appearance="styled"
                disabled={props.disabled}
                required={!props.disabled ? 'Gender is required' : false}
              >
                {Object.entries(GENDER_NAMES).map(([genderCode, genderName]) => (
                  <Select.Option key={genderCode} label={genderName} value={genderCode} />
                ))}
              </Field>
            </Flex.Item>
          </Flex>
          <Heading variant="titleS" level={3}>
            ✉️ Full Mailing Address
          </Heading>
          <Flex>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={Combobox}
                label="Country"
                name="country"
                size="md"
                placeholder="Select"
                disabled={props.disabled}
                required={!props.disabled ? 'Country is required' : false}
              >
                {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                  <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
                ))}
              </Field>
            </Flex.Item>
          </Flex>
          <Flex pb={6} gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Street address"
                name="streetAddress"
                disabled={props.disabled}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Apartment, suite, unit (optional)"
                name="unitNumber"
                disabled={props.disabled}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field as={TextInput} label="City/town" name="city" disabled={props.disabled} />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Province/state/area"
                name="province"
                disabled={props.disabled}
                required={!props.disabled ? 'Province/state/area is required' : false}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label="Postal/zip code"
                name="postalCode"
                disabled={props.disabled}
                required={!props.disabled ? 'Postal/zip code' : false}
              />
            </Flex.Item>
          </Flex>
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}
