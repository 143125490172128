import { useMutation } from '@tanstack/react-query'
import { request } from '../utils/request'

type ResendConfirmationProps = {
  id: string
}

export function useResendSignUpConfirmation(props: ResendConfirmationProps) {
  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      return await request(`/users/${props.id}/resend-confirmation`, {
        method: 'POST',
        isExpectedResponse: (res): res is unknown => true,
        body: {
          id: props.id,
        },
      })
    },
  })

  return {
    isSendingConfirmation: isPending,
    resendConfirmation: mutate,
  }
}
