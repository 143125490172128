import { useAuthQuery } from './auth'
import { request } from '../utils/request'
import { VersionedApplicationResourceWithFiles } from 'applications-types-lib'

type GetApplicationProps = {
  id?: string
}

export type RawApplicationResponse = {
  data: VersionedApplicationResourceWithFiles
}

export function useGetApplication(props: GetApplicationProps) {
  const { isLoading, data } = useAuthQuery(
    ['applications', props.id],
    async () => {
      return await request<RawApplicationResponse>(`/applications/${props.id}`, {
        method: 'GET',
        isExpectedResponse: (res): res is RawApplicationResponse => res,
      })
    },
    {
      enabled: !!props.id,
    },
  )

  return {
    isLoadingApplication: isLoading,
    application: data?.data || null,
  }
}
