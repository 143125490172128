import { useState } from 'react'
import { OnBoardingBox } from '../../components/OnBoardingBox'
import { CongratulationsConfirmationCard } from './CongratulationsConfirmationCard'
import { SignUpConfirmationForm } from './SignUpConfirmationForm'

export function SignUpConfirmationPage() {
  const [showCongratulations, setShowCongratulations] = useState(false)
  const [newPassword, setNewPassword] = useState('')

  return (
    <OnBoardingBox>
      {showCongratulations ? (
        <CongratulationsConfirmationCard newPassword={newPassword} />
      ) : (
        <SignUpConfirmationForm
          onSuccess={newPassword => {
            setNewPassword(newPassword)
            setShowCongratulations(true)
          }}
        />
      )}
    </OnBoardingBox>
  )
}
