import styled from '@emotion/styled'
import { Flex, HelpText, Text } from '@applyboard/crystal-ui'
import { CloseOutlineIcon, DownloadOutlineIcon, InfoOutlineIcon } from '@applyboard/ui-icons'

type ApplicationFileLinkProps = {
  fileId: string
  filename: string
  helpText?: string
  intent: 'secondary' | 'negative'
  link: string
  onDelete?: (fileId: string) => void
}

export function ApplicationFileLink(props: ApplicationFileLinkProps) {
  return (
    <ListElement>
      <FileChip intent={props.intent}>
        <FileLabel fileName={props.filename} intent={props.intent} link={props.link}>
          {props.intent === 'negative' ? (
            <InfoOutlineIcon intent={props.intent} size="md" />
          ) : (
            <DownloadOutlineIcon intent={props.intent} size="md" />
          )}
          <Flex direction={props.helpText ? 'column' : 'row'}>
            <Text variant="labelM" intent={props.intent}>
              {props.filename}
            </Text>
            {!!props.helpText ? <HelpText intent={props.intent}>{props.helpText}</HelpText> : null}
          </Flex>
        </FileLabel>
        {!!props.onDelete ? (
          <CloseButton
            aria-label={`Remove file ${props.filename}`}
            type="button"
            intent={props.intent}
            onClick={() => {
              if (props.onDelete) {
                props.onDelete(props.fileId)
              }
            }}
          >
            <CloseOutlineIcon intent={props.intent} />
          </CloseButton>
        ) : null}
      </FileChip>
    </ListElement>
  )
}

function FileLabel({
  children,
  fileName,
  intent,
  link,
}: {
  children: React.ReactNode
  fileName: string
  intent: 'secondary' | 'negative'
  link: string
}) {
  if (!link) {
    return <FileDiv intent={intent}>{children}</FileDiv>
  }

  return (
    <FileLink intent={intent} href={link} target="_blank" aria-label={`Download file ${fileName}`}>
      {children}
    </FileLink>
  )
}

const ListElement = styled.li({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
})

const FileChip = styled.div((props: { intent: string }) => ({
  alignItems: 'center',
  backgroundColor: props.intent === 'negative' ? 'rgb(254, 246, 248)' : 'rgb(246, 248, 254)',
  borderRadius: '8px',
  display: 'flex',
  width: '100%',
  height: '48px',
  justifyContent: 'space-between',
}))

const FileLink = styled.a((props: { intent: string }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: '16px',
  paddingLeft: '16px',
  borderRadius: '8px 0 0 8px',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: props.intent === 'negative' ? 'rgb(254, 232, 238)' : 'rgb(234, 238, 247)',
  },
}))

const FileDiv = styled.div((props: { intent?: string }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: '16px',
  paddingLeft: '16px',
  borderRadius: '8px 0 0 8px',
  textDecoration: 'none',
}))

const CloseButton = styled.button((props: { intent: string }) => ({
  borderRadius: '0px 8px 8px 0px',
  minWidth: 'unset',
  position: 'relative',
  whiteSpace: 'nowrap',
  borderColor: 'transparent',
  borderWidth: '1px',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  height: '48px',
  width: '48px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: props.intent === 'negative' ? 'rgb(254, 232, 238)' : 'rgb(234, 238, 247)',
  },
}))
