import React from 'react'
import { useMatch } from 'react-router-dom'
import { ButtonLink } from '@applyboard/crystal-ui'
import { SvgIconProps } from '@applyboard/ui-icons'
interface INavBarItem {
  children: string | undefined
  id: string
  href: string
  label: string
  icon: React.ComponentType<Omit<SvgIconProps, 'children'>>
}

export function NavBarItem(props: INavBarItem) {
  const match = useMatch(props.href)

  return (
    <ButtonLink
      href={props.href}
      leadIcon={props.icon}
      width="fill"
      intent={!!match ? 'primary' : 'secondary'}
      emphasis={!!match ? 'highlighted' : 'transparent'}
      aria-label={props.label}
    />
  )
}
