import { useAuthMutation } from './auth'
import { request } from '../utils'
import { VersionedApplicationResourceWithFiles } from 'applications-types-lib'

type SubmitApplicationProps = {
  id: string
}

type SubmitApplicationParams = undefined

type RawApplicationsResponse = {
  data: VersionedApplicationResourceWithFiles
}

export function useSubmitApplication(props: SubmitApplicationProps) {
  const { isPending, mutate } = useAuthMutation(async (params: SubmitApplicationParams) => {
    return await request<RawApplicationsResponse>(`/applications/${props.id}/submit`, {
      method: 'POST',
      isExpectedResponse: (res): res is RawApplicationsResponse => res,
    })
  })

  return {
    isSubmittingApplication: isPending,
    submitApplication: mutate,
  }
}
